import { useRef, useState } from "react";
import type { Placement, OpenChangeReason } from "@floating-ui/react";
import {
  useFloating,
  arrow,
  FloatingArrow,
  offset,
  shift,
  flip,
  useTransitionStyles,
  autoUpdate,
  useDismiss,
  useInteractions,
  FloatingOverlay,
  FloatingPortal,
  FloatingFocusManager,
} from "@floating-ui/react";
import {
  Scrollbar,
  Tooltip,
  TooltipContent,
  Badge,
  TooltipTrigger,
} from "@the-immersive/components";
import {
  isPopoverOpenSignal,
  useSignalEffect,
  currentStepNameSignal,
  hasCorrectAnswerMessageBeenSeen,
  machineExplodingState,
} from "@the-immersive/signals";

import { Puzzle_Steps_Enum } from "@the-immersive/types";

import "./popover.scss";

const ARROW_WIDTH = 30;
const ARROW_HEIGHT = 15;

export type OnChangeParams = {
  isNowOpen: boolean;
  event?: Event | undefined;
  reason?: OpenChangeReason | undefined;
};

export type PopoverProps = {
  children?: React.ReactNode;
  open?: boolean;
  onChange?: (args: OnChangeParams) => void;
  style?: React.CSSProperties;
  triggerText?: string;
  triggerIcon?: string;
  // triggerSVG?: string;
};

export const Popover = ({
  children,
  onChange,
  style,
  triggerIcon,
}: PopoverProps) => {
  const [placement] = useState<Placement>("bottom");
  const [isOpen, setIsOpen] = useState(isPopoverOpenSignal.value);

  const refocusElement = useRef<HTMLButtonElement | HTMLInputElement>();

  useSignalEffect(() => {
    if (isPopoverOpenSignal.value !== isOpen) {
      if (document.getElementById("answer-input")) {
        refocusElement.current = document.getElementById(
          "answer-input"
        ) as HTMLInputElement;
      } else {
        refocusElement.current = context.refs.domReference
          .current as HTMLButtonElement;
      }

      setIsOpen(isPopoverOpenSignal.value);

      setTimeout(() => {
        const scrollElem = refs.floating.current?.querySelector(
          "#r-b-s-bottom"
        ) as HTMLElement;

        if (scrollElem) {
          scrollElem.scrollIntoView({
            behavior: "instant",
            block: "nearest",
            inline: "nearest",
          });
        }
      }, 100);
    }
  });

  function setIsOpenCallback(
    isNowOpen: boolean,
    event?: Event | undefined,
    reason?: OpenChangeReason | undefined
  ) {
    // console.log("setIsOpenCallback");
    if (onChange) {
      onChange({
        isNowOpen,
        event,
        reason,
      });
    }

    const elem = refocusElement.current as HTMLElement;
    elem.focus();

    isPopoverOpenSignal.value = isNowOpen;

    hasCorrectAnswerMessageBeenSeen.value = true;

    setIsOpen(isNowOpen);
  }

  const arrowRef = useRef(null);

  // setTimeout(() => {
  //   const elem = promptRef.current;
  //   if (!elem) return;
  //   elem.scrollTop = elem?.scrollHeight;
  // }, 10);

  const { refs, floatingStyles, context, middlewareData } = useFloating({
    placement,
    open: isOpen,
    onOpenChange: setIsOpenCallback,
    strategy: "fixed",
    middleware: [
      offset(12),
      flip({ padding: 5 }),
      shift({ padding: 5 }),
      arrow({ element: arrowRef }),
    ],
    whileElementsMounted: autoUpdate,
  });

  const dismiss = useDismiss(context);

  const { getReferenceProps, getFloatingProps } = useInteractions([dismiss]);

  const arrowX = middlewareData.arrow?.x ?? 0;
  const arrowY = middlewareData.arrow?.y ?? 0;
  const transformX = arrowX + ARROW_WIDTH / 2;
  const transformY = arrowY + ARROW_HEIGHT;

  const { isMounted, styles } = useTransitionStyles(context, {
    initial: {
      transform: "scale(0)",
    },
    common: ({ side }) => ({
      transformOrigin: {
        top: `${transformX}px calc(100% + ${ARROW_HEIGHT}px)`,
        bottom: `${transformX}px ${-ARROW_HEIGHT}px`,
        left: `calc(100% + ${ARROW_HEIGHT}px) ${transformY}px`,
        right: `${-ARROW_HEIGHT}px ${transformY}px`,
      }[side],
    }),
  });

  const popoverButton = (
    <button
      ref={refs.setReference}
      className="c-icon-button"
      onClick={(e) => {
        setIsOpen(!isOpen);
        refocusElement.current = e.currentTarget;

        setTimeout(() => {
          const scrollElem = refs.floating.current?.querySelector(
            "#r-b-s-bottom"
          ) as HTMLElement;

          if (scrollElem) {
            scrollElem.scrollIntoView({
              behavior: "instant",
              block: "nearest",
              inline: "nearest",
            });
          }
        }, 200);
      }}
      {...getReferenceProps()}
      style={{
        marginLeft: placement === "left" ? "16rem" : undefined,
        ...style,
      }}
    >
      {/* todo: add visually hidden wrapper */}
      {/* {triggerText || "Trigger"} */}
      {triggerIcon && (
        <>
          {currentStepNameSignal.value === Puzzle_Steps_Enum.FinalStep &&
          machineExplodingState.value === "repaired" ? (
            <Badge>
              <img
                src={triggerIcon}
                className="c-popover__trigger-icon"
                height="50"
                width="50"
              />
            </Badge>
          ) : (
            <img
              src={triggerIcon}
              className="c-popover__trigger-icon"
              height="50"
              width="50"
            />
          )}
        </>
      )}
    </button>
  );

  return (
    <>
      {currentStepNameSignal.value === Puzzle_Steps_Enum.FinalStep &&
      machineExplodingState.value === "repaired" ? (
        <Tooltip open placement="bottom-end">
          {/* <Badge> */}
          <TooltipTrigger asChild={true}>{popoverButton}</TooltipTrigger>
          {/* </Badge> */}
          <TooltipContent>{"1 unread message"}</TooltipContent>
        </Tooltip>
      ) : (
        <>{popoverButton}</>
      )}

      <FloatingPortal>
        <FloatingOverlay
          className={`c-popover-overlay c-popover-overlay--${
            isOpen ? "visible" : "hidden"
          }`}
          lockScroll={isOpen}
        >
          {isMounted && isOpen && (
            <FloatingFocusManager context={context} initialFocus={1}>
              <div
                ref={refs.setFloating}
                style={floatingStyles}
                {...getFloatingProps()}
                className="c-popover"
              >
                <header className="c-chat__header" style={styles}>
                  {/* <span className="c-chat__header-text">SystemLogs.exe</span> */}
                  <button
                    className="c-chat__dismiss"
                    onClick={() => {
                      setIsOpenCallback(false, undefined, "click");
                    }}
                  >
                    ×
                  </button>
                </header>
                <div style={styles} className="floating c-chat" tabIndex={0}>
                  <Scrollbar minScroll={0}>{children}</Scrollbar>
                  <FloatingArrow
                    ref={arrowRef}
                    context={context}
                    width={ARROW_WIDTH}
                    height={ARROW_HEIGHT}
                  />
                </div>
              </div>
            </FloatingFocusManager>
          )}
        </FloatingOverlay>
      </FloatingPortal>
    </>
  );
};

export const AnswersPopover = ({
  children,
  onChange,
  style,
  triggerIcon,
}: PopoverProps) => {
  const [placement] = useState<Placement>("bottom-end");
  const [isOpen, setIsOpen] = useState(false);

  function setIsOpenCallback(
    isNowOpen: boolean,
    event?: Event | undefined,
    reason?: OpenChangeReason | undefined
  ) {
    if (onChange) {
      onChange({
        isNowOpen,
        event,
        reason,
      });
    }
    setIsOpen(isNowOpen);
  }

  const arrowRef = useRef(null);

  const { refs, floatingStyles, context, middlewareData } = useFloating({
    placement,
    open: isOpen,
    onOpenChange: setIsOpenCallback,
    strategy: "fixed",
    middleware: [
      offset(12),
      flip({ padding: 5 }),
      shift({ padding: 5 }),
      arrow({ element: arrowRef }),
    ],
    whileElementsMounted: autoUpdate,
  });

  const dismiss = useDismiss(context);

  const { getReferenceProps, getFloatingProps } = useInteractions([dismiss]);

  const arrowX = middlewareData.arrow?.x ?? 0;
  const arrowY = middlewareData.arrow?.y ?? 0;
  const transformX = arrowX + ARROW_WIDTH / 2;
  const transformY = arrowY + ARROW_HEIGHT;

  const { isMounted, styles } = useTransitionStyles(context, {
    initial: {
      transform: "scale(0)",
    },
    common: ({ side }) => ({
      transformOrigin: {
        top: `${transformX}px calc(100% + ${ARROW_HEIGHT}px)`,
        bottom: `${transformX}px ${-ARROW_HEIGHT}px`,
        left: `calc(100% + ${ARROW_HEIGHT}px) ${transformY}px`,
        right: `${-ARROW_HEIGHT}px ${transformY}px`,
      }[side],
    }),
  });

  return (
    <>
      <button
        ref={refs.setReference}
        className="c-icon-button"
        onClick={() => {
          setIsOpen(!isOpen);
        }}
        {...getReferenceProps()}
        style={{
          marginLeft: placement === "left" ? "16rem" : undefined,
          ...style,
        }}
      >
        {/* todo: add visually hidden wrapper */}
        {/* {triggerText || "Trigger"} */}
        {triggerIcon && (
          <img
            src={triggerIcon}
            className="c-popover__trigger-icon"
            height="50"
            width="50"
          />
        )}
      </button>
      {isMounted && isOpen && (
        <FloatingPortal>
          <div
            ref={refs.setFloating}
            style={floatingStyles}
            {...getFloatingProps()}
            className="c-popover"
          >
            <div style={styles} className="floating">
              <Scrollbar minScroll={0}>{children}</Scrollbar>
              <FloatingArrow
                ref={arrowRef}
                context={context}
                width={ARROW_WIDTH}
                height={ARROW_HEIGHT}
              />
            </div>
          </div>
        </FloatingPortal>
      )}
    </>
  );
};

export const VolumePopover = ({
  children,
  onChange,
  style,
  triggerSVG,
}: PopoverProps & {
  triggerSVG: string;
}) => {
  const [placement] = useState<Placement>("top");
  const [isOpen, setIsOpen] = useState(false);

  function setIsOpenCallback(
    isNowOpen: boolean,
    event?: Event | undefined,
    reason?: OpenChangeReason | undefined
  ) {
    if (onChange) {
      onChange({
        isNowOpen,
        event,
        reason,
      });
    }
    setIsOpen(isNowOpen);
  }

  const arrowRef = useRef(null);

  const { refs, floatingStyles, context, middlewareData } = useFloating({
    placement,
    open: isOpen,
    onOpenChange: setIsOpenCallback,
    middleware: [
      offset(-5),
      flip({ padding: 5 }),
      shift({ padding: 5 }),
      arrow({ element: arrowRef }),
    ],
    whileElementsMounted: autoUpdate,
  });

  const dismiss = useDismiss(context);

  const { getReferenceProps, getFloatingProps } = useInteractions([dismiss]);

  const arrowX = middlewareData.arrow?.x ?? 0;
  const arrowY = middlewareData.arrow?.y ?? 0;
  const transformX = arrowX + ARROW_WIDTH / 2;
  const transformY = arrowY + ARROW_HEIGHT;

  const { isMounted, styles } = useTransitionStyles(context, {
    initial: {
      transform: "scale(0)",
    },
    common: ({ side }) => ({
      transformOrigin: {
        top: `${transformX}px calc(100% + ${ARROW_HEIGHT}px)`,
        bottom: `${transformX}px ${-ARROW_HEIGHT}px`,
        left: `calc(100% + ${ARROW_HEIGHT}px) ${transformY}px`,
        right: `${-ARROW_HEIGHT}px ${transformY}px`,
      }[side],
    }),
  });

  return (
    <>
      <button
        ref={refs.setReference}
        className="c-icon-button c-icon-button--audio-control"
        onClick={() => {
          setIsOpen(!isOpen);
        }}
        {...getReferenceProps()}
        style={{
          marginLeft: placement === "left" ? "16rem" : undefined,
          ...style,
        }}
      >
        {/* todo: add visually hidden wrapper */}
        {/* {triggerText || "Trigger"} */}
        <svg>
          <use xlinkHref={`#${triggerSVG}`} />
        </svg>
      </button>
      {isMounted && isOpen && (
        <div
          ref={refs.setFloating}
          style={floatingStyles}
          {...getFloatingProps()}
          className="c-popover c-audio-popover"
        >
          <div style={styles} className="floating c-audio-popover__body">
            {children}
            {/* <FloatingArrow
              ref={arrowRef}
              context={context}
              width={ARROW_WIDTH}
              height={ARROW_HEIGHT}
            /> */}
          </div>
        </div>
      )}
    </>
  );
};
