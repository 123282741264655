/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useRef } from "react";
import interact from "interactjs";
import postcard from "./postcard.jpg";
import { ScreenSize } from "@the-immersive/components";
import "./postcard.scss";

export const Postcard = () => {
  const previousValue = localStorage.getItem("measured-size");
  const inputRef = useRef<HTMLInputElement>(null);
  const viewRef = useRef<HTMLDivElement>(null);
  const mainRef = useRef<HTMLDivElement>(null);
  const creditRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!creditRef.current) return;

    interact(creditRef.current).resizable({
      // resize from all edges and corners
      edges: { left: true, right: true, bottom: true, top: true },
      // margin: creditRef.current.getBoundingClientRect().width / 1,
      listeners: {
        move(event) {
          const target = event.target;
          let x = parseFloat(target.getAttribute("data-x")) || 0;
          let y = parseFloat(target.getAttribute("data-y")) || 0;

          // translate when resizing from top or left edges
          x += event.deltaRect.left;
          y += event.deltaRect.top;

          target.setAttribute("data-x", x);
          target.setAttribute("data-y", y);

          if (sc.current) {
            const newRatio = 1 / (254 / (event.rect.width * 3.55));
            sc.current.setRatio(newRatio);

            localStorage.setItem("measured-size", newRatio.toString());

            if (inputRef.current) {
              inputRef.current.value = newRatio.toString();
            }
          }
        },
      },
      modifiers: [
        // minimum size
        interact.modifiers.restrictSize({
          min: { width: 190, height: 190 },
          max: { width: 1000, height: 724 },
        }),

        interact.modifiers.aspectRatio({ ratio: "preserve" }),
      ],

      inertia: true,
    });
  });

  const sc = useRef<ReturnType<typeof ScreenSize>>();

  function update(_ratio: number) {
    if (!sc.current) return;
    if (!creditRef.current) return;
  }

  useEffect(() => {
    if (viewRef.current && mainRef.current && creditRef.current) {
      sc.current = ScreenSize({
        node: viewRef.current,
        creditElem: creditRef.current,
        mainElem: mainRef.current,
        onChange: update,
        rulersOrientation: "horizontal",
      });
    }
  });

  useEffect(() => {
    if (previousValue && inputRef.current) {
      inputRef.current.value = previousValue;
      inputRef.current.setAttribute("value", previousValue);

      if (sc.current) {
        sc.current.setRatio(parseFloat(previousValue));
        update(sc.current.getRatio());
      }
    }
  }, [previousValue, sc]);

  return (
    <div
      className="c-overlay-page"
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        flexGrow: 1,
      }}
    >
      <div
        id="view"
        ref={viewRef}
        className="c-view--postcard"
        style={{
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          className="measure-display-view measure-display-view--postcard horizontal-rulers"
          ref={mainRef}
        >
          <div
            className="c-resizable-image c-resizable-image--postcard"
            ref={creditRef}
            style={{
              aspectRatio: "1000 / 724",
              backgroundRepeat: "no-repeat",
              backgroundSize: "contain",
              backgroundPosition: "center center",
              backgroundImage: `url(${postcard})`,
              touchAction: "none",
            }}
          ></div>
        </div>
      </div>
      <div
        id="control"
        style={{
          marginTop: "auto",
          display: "flex",
          alignItems: "end",
          padding: "1rem",
        }}
      >
        <input
          type="range"
          ref={inputRef}
          id="ratio"
          name="scale"
          min="3"
          max="14"
          step="0.1"
          defaultValue="4"
          onChange={(e) => {
            localStorage.setItem("measured-size", e.target.value);
          }}
          onInput={(e) => {
            if (sc.current) {
              sc.current.setRatio(parseFloat(e.currentTarget.value));
            }
          }}
        />
        <button
          id="reset-button"
          className="c-prompt__submit"
          style={{
            marginBottom: "-12px",
            marginLeft: "16px",
            fontSize: "1.5rem",
            padding: "0.2rem 0.8rem",
            borderColor: "hsl(41 51% 30% / 1)",
            color: "hsl(41 51% 50% / 1)",
          }}
          type="button"
          onClick={() => {
            localStorage.removeItem("measured-size");

            if (inputRef.current) {
              inputRef.current.setAttribute("value", "4");
              inputRef.current.value = "4";
            }

            if (sc.current) {
              localStorage.removeItem("measured-size");

              sc.current.setRatio(4);
              update(sc.current.getRatio());
            }
          }}
        >
          Reset
        </button>
      </div>
    </div>
  );
};
