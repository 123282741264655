// import React from "react";
import "./cctv-grid.scss";

import { Puzzle_Steps_Enum } from "@the-immersive/gql";

const cctv = Object.values(
  import.meta.glob("../../assets/images/cctv/theater-*", {
    eager: true,
    as: "url",
  })
);

import cipherImage from "@the-immersive/images/cctv/999-cipher.gif";
import postcardVideoMp4 from "@the-immersive/images/cctv/999-postcard.mp4";
import postcardVideoWebM from "@the-immersive/images/cctv/999-postcard.webm";
import constellationImage from "@the-immersive/images/cctv/999-constellation.gif";

const gridItems: string[] = [
  ...cctv,
  postcardVideoMp4,
  // postcardVideoWebM,
  cipherImage,
  constellationImage,
];

export const CCTVGrid = ({
  currentStep = Puzzle_Steps_Enum["Step1"],
}: {
  currentStep?: Puzzle_Steps_Enum;
}) => {
  const isLastStep = currentStep === Puzzle_Steps_Enum.FinalStep;
  const isStarsStep = currentStep === Puzzle_Steps_Enum.Step6;
  const isPostcardStep = currentStep === Puzzle_Steps_Enum.Step9;
  const isRunesStep = currentStep === Puzzle_Steps_Enum.Step13;

  const items = gridItems
    .filter((item) => !item.includes(isLastStep ? "10a" : "10b"))
    .filter((item) => {
      if (item.includes("constellation")) {
        if (isStarsStep) return item;
        return null;
      } else if (item.includes("cipher")) {
        if (isRunesStep) return item;
        return false;
      } else if (item.includes("postcard")) {
        if (isPostcardStep) return item;
        return false;
      } else {
        return item;
      }
    })
    .sort(function (x, y) {
      const num1 = parseInt(x.replace(/[^0-9]/g, ""), 10);
      const num2 = parseInt(y.replace(/[^0-9]/g, ""), 10);

      return num1 - num2;
    });

  return (
    <div className="c-cctv-grid">
      {items.map((item) => {
        if (item.includes("999")) {
          let gridItemUrl = "#";

          if (item.includes("constellation")) {
            gridItemUrl = "/stars";
          } else if (item.includes("cipher")) {
            gridItemUrl = "/runes";
          } else if (item.includes("postcard")) {
            gridItemUrl = "/postcard";
          }

          return (
            <a
              key={item}
              className="c-cctv-grid__item"
              href={gridItemUrl}
              target="_blank"
            >
              <h2 className="c-cctv-grid__item-text">{`<?_^%#}>@!_(*&”{\\][/`}</h2>
              <div
                className="c-cctv-grid__item-inner"
                style={{
                  animation: "glow 2500ms ease-in-out infinite alternate",
                }}
              >
                {item.includes("postcard") ? (
                  <video
                    width="1000"
                    height="724"
                    autoPlay={true}
                    playsInline
                    loop
                    style={{
                      zIndex: 1000,
                      gridRow: "1/1",
                      gridColumn: "1/1",
                      // objectFit: "fill",
                      // position: "absolute",
                      top: "0",
                      left: "0",
                      bottom: "0",
                      right: "0",
                      aspectRatio: "1000/724",
                      maxHeight: "100%",
                      maxWidth: "100%",
                      height: "100%",
                    }}
                  >
                    <source
                      src={postcardVideoMp4}
                      type='video/mp4; codecs="hvc1"'
                    />
                    <source src={postcardVideoWebM} type="video/webm" />
                  </video>
                ) : (
                  <img
                    src={item}
                    style={{
                      animation: "hoverY 7s infinite alternate",
                    }}
                  />
                )}
                <div
                  className="c-scanlines"
                  style={{
                    animationDelay: `${Math.abs(
                      Math.random() * (0.5 - 5) + 5
                    ).toString()}s`,
                  }}
                />
                <div
                  className="c-overlay"
                  style={{
                    animationDelay: `${Math.abs(
                      Math.random() * (0.5 - 5) + 5
                    ).toString()}s`,
                  }}
                />
              </div>
            </a>
          );
        } else {
          return (
            <div className="c-cctv-grid__item" key={item}>
              <h2 className="c-cctv-grid__item-text">
                Theater {parseInt(item.replace(/[^0-9]/g, ""), 10)}
              </h2>
              <div className="c-cctv-grid__item-inner">
                <img
                  src={item}
                  style={{
                    animation: `5s ease 2000ms normal none infinite running glitch`,
                    animationDelay: `${Math.abs(
                      Math.random() * (5000 - 30000) + 30000
                    ).toString()}ms`,
                  }}
                ></img>
                <div
                  className="c-scanlines"
                  style={{
                    animationDelay: `${Math.abs(
                      Math.random() * (0.5 - 5) + 5
                    ).toString()}s`,
                  }}
                />
                <div
                  className="c-overlay"
                  style={{
                    animationDelay: `${Math.abs(
                      Math.random() * (0.5 - 5) + 5
                    ).toString()}s`,
                  }}
                />
              </div>
            </div>
          );
        }
      })}
    </div>
  );
};
