import { useState, useRef } from "react";

import ReactModal from "react-modal";

ReactModal.setAppElement("#root");

import { soundEffects } from "@the-immersive/components";

import { useSignalEffect, isModalOpenSignal } from "@the-immersive/signals";
import "./modal.scss";

type ModalProps = {
  isOpen?: boolean;
  // isOpen={modalIsOpen}
  onAfterOpen?: () => void;
  onAfterClose?: () => void;

  onRequestClose?: () => void;
  style?: React.CSSProperties;
  children?: React.ReactNode;
};

export const Modal = ({
  children,
  onAfterClose,
  onRequestClose,
  onAfterOpen,
}: ModalProps) => {
  const [, setIsOpen] = useState(isModalOpenSignal.value);

  const buttonRef = useRef<HTMLButtonElement>(null);
  const modalContentRef = useRef<HTMLDivElement>();

  useSignalEffect(() => {
    setIsOpen(isModalOpenSignal.value);

    if (
      isModalOpenSignal.value === true &&
      modalStateRef.current === "closed"
    ) {
      soundEffects.modal.opening.play();
    } else if (modalStateRef.current === "closing") {
      soundEffects.modal.closing.play();
    }
  });

  const modalRef = useRef<ReactModal>(null);

  const modalStateRef = useRef<"closed" | "closing" | "opened" | "opening">(
    "closed"
  );

  function requestClose() {
    modalStateRef.current = "closing";
    isModalOpenSignal.value = false;

    modalStateRef.current = "closing";

    document.body.classList.remove(
      ...["c-modal-body--is-opened", "c-modal-body--is-opening"]
    );
    document.body.classList.add(...["c-modal-body--is-closing"]);

    setTimeout(() => {
      modalStateRef.current = "closed";
      document.body.classList.add(...["c-modal-body--is-closed"]);
      document.body.classList.remove(...["c-modal-body--is-closing"]);
      if (onAfterClose) onAfterClose();
    }, 300);

    if (onRequestClose) onRequestClose;
  }

  return (
    <ReactModal
      isOpen={isModalOpenSignal.value}
      closeTimeoutMS={1000}
      preventScroll
      shouldFocusAfterRender={true}
      shouldReturnFocusAfterClose={true}
      contentRef={(node) => {
        modalContentRef.current = node;
      }}
      ref={modalRef}
      onRequestClose={() => {
        requestClose();
      }}
      bodyOpenClassName={null}
      onAfterOpen={() => {
        if (modalContentRef.current) {
          modalContentRef.current.removeAttribute("tabindex");
        }

        if (buttonRef.current) {
          buttonRef.current.focus();
        }

        modalStateRef.current = "opening";
        document.body.classList.add(...["c-modal-body--is-opening"]);
        document.body.classList.remove(
          ...["c-modal-body--is-closed", "c-modal-body--is-closing"]
        );

        setTimeout(() => {
          if (modalStateRef.current === "closing") return;

          modalStateRef.current = "opened";
          document.body.classList.add("c-modal-body--is-opened");
          document.body.classList.remove(
            ...[
              "c-modal-body--is-opening",
              "c-modal-body--is-closing",
              "c-modal-body--is-closed",
            ]
          );
        }, 1000);

        if (onAfterOpen) onAfterOpen;
      }}
    >
      <button
        className="c-icon-button"
        tabIndex={1}
        onClick={() => {
          requestClose();
        }}
        ref={buttonRef}
        style={{
          position: "absolute",
          top: "4px",
          right: "4px",
          zIndex: 1000,
          fontSize: "2rem",
          height: "44px",
          width: "44px",
          fontFamily: "breamcatcher",
        }}
      >
        ×
      </button>
      {children}
    </ReactModal>
  );
};
