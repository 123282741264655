import { useRef, useEffect } from "react";
import { Eye, Pointer } from "./eyeball";
import "./eye.scss";

export const Eyeball = ({ stop }: { stop?: boolean }) => {
  const eyeRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!eyeRef.current) return;
    const eyeLeft = new Eye(eyeRef.current);
    const eyes: Eye[] = [eyeLeft];

    const pointer = new Pointer(eyes);

    if (stop === true) {
      pointer.stop();
    }
  }, [stop]);

  return (
    <div className="eyes">
      <div
        style={{
          overflow: "hidden",
          borderRadius: "50rem",
          display: "grid",
          alignItems: "center",
          justifyContent: "center",
          gridTemplateColumns: "1fr",
          gridTemplateRows: "1fr",
          clipPath: "circle(110px at 128px 128px)",
        }}
      >
        <div className="eye-iris eye-iris-left flex" ref={eyeRef}>
          <div className="eye-pupil eye-pupil-left flex"></div>
        </div>
      </div>
      <div className="eye eye-left flex"></div>
    </div>
  );
};
