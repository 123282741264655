import "./wormhole.jquery";
import "./wormhole.scss";

export const Wormhole = () => {
  return (
    <div
      id="jsi-wormhole-container"
      className="c-wormhole"
      style={{
        width: "500px",
        height: "500px",
        marginLeft: "auto",
        marginRight: "auto",
      }}
    ></div>
  );
};
