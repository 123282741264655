import React from "react";
import { ScrollWrapper } from "react-chat-bottom-pin";
import "./scrollbar.scss";

export type ScrollbarProps = {
  style?: React.CSSProperties;
  children?: React.ReactNode;
  minScroll?: number;
  // smoothBehavior?: boolean;
  // topCallback?: () => void;
  // bottomCallback?: () => void;
};

export const Scrollbar = ({ children, minScroll = 100 }: ScrollbarProps) => {
  // const topCallback = () => {
  //   // callback to load new content at the top of the wrapper. eg loading more chat at the top
  //   console.log("Reached top of container");

  //   setMinScrollValue(50);
  // };

  // const [minScrollValue, setMinScrollValue] = useState(minScroll);

  // const bottomCallback = () => {
  //   //callback to handle reaching bottom of the page eg. setting read status to true
  //   console.log("Reached bottom of container");
  // };

  return (
    <ScrollWrapper
      minScroll={minScroll}
      smoothBehavior
      // topCallback={}
      // topCallback={topCallback}
      // bottomCallback={bottomCallback}
    >
      {children}
    </ScrollWrapper>
  );
};
