/* eslint-disable no-irregular-whitespace */
import { useEffect, useState, useRef } from "react";
// import textReadout from "../../assets/audio/effects/futuristic-computer-typing.mp3";
// import selectSound from "../../assets/select.mp3";
import "./mainframe.scss";
// import "../../screens/login/login.scss";
// import "../button/button.scss";
import { MainframeInner } from "./mainframe-inner";
import {
  isEyeballVisibleSignal,
  useSignalEffect,
  currentHintsSignal,
  machineExplodingState,
  videoTransitionState,
  hasCorrectAnswerMessageBeenSeen,
} from "@the-immersive/signals";
import {
  Scrollbar,
  // electricalSparksEffect,
  // shuttingDownEffect,
  // machineFixedEffect,
  // gameTextReadoutEffect,
  soundEffects,
} from "@the-immersive/components";

import TypeIt from "typeit-react";
import { default as TypeItCore } from "typeit";

// export const hasMachineBeenFixed = signal<boolean>(false);

// import AudioPlayer from "react-h5-audio-player";

export async function adjustVolume(
  element: HTMLMediaElement,
  newVolume: number,
  {
    duration = 1000,
    easing = swing,
    interval = 13,
  }: {
    duration?: number;
    easing?: typeof swing;
    interval?: number;
  } = {}
): Promise<void> {
  const originalVolume = element.volume;
  const delta = newVolume - originalVolume;

  if (!delta || !duration || !easing || !interval) {
    element.volume = newVolume;
    return Promise.resolve();
  }

  const ticks = Math.floor(duration / interval);
  let tick = 1;

  return new Promise((resolve) => {
    const timer = setInterval(() => {
      element.volume = originalVolume + easing(tick / ticks) * delta;

      if (++tick === ticks + 1) {
        clearInterval(timer);
        resolve();
      }
    }, interval);
  });
}

export function swing(p: number) {
  return 0.5 - Math.cos(p * Math.PI) / 2;
}

export type MainframeParams = {
  imagePath?: string;
  prompt: null | ((instance: TypeItCore) => TypeItCore);
  numberOfSteps: number;
  currentStep: number;
  children?: React.ReactNode;
};

export const Mainframe = ({
  imagePath,
  prompt,
  numberOfSteps,
  currentStep,
  children,
}: MainframeParams) => {
  const [isPromptVisible, setIsPromptVisible] = useState(false);

  const typeItInstance = useRef<TypeItCore>();
  const [activeStep, setActiveStep] = useState<number>(currentStep);
  const promptRef = useRef<HTMLDivElement>(null);

  const hintsGiven = useRef<number>(0);

  useSignalEffect(() => {
    if (
      currentStep === 1 &&
      machineExplodingState.value !== "exploded" &&
      machineExplodingState.value !== "exploding" &&
      isEyeballVisibleSignal.value === false
    ) {
      //

      soundEffects.mainframeShutdown.once("play", () => {
        setTimeout(() => {
          machineExplodingState.value = "exploded";
          soundEffects.mainframeShutdown.fade(0.6, 0, 400);

          setIsPromptVisible(true);
        }, 2500);
      });

      soundEffects.mainframeShutdown.play();
      machineExplodingState.value = "exploding";
    } else if (
      currentStep === numberOfSteps &&
      machineExplodingState.value !== "repairing" &&
      machineExplodingState.value !== "repaired"
    ) {
      machineExplodingState.value = "repairing";
    } else if (
      currentStep !== 1 &&
      currentStep !== numberOfSteps &&
      machineExplodingState.value !== "exploded" &&
      machineExplodingState.value !== "exploding" &&
      isEyeballVisibleSignal.value === false
    ) {
      machineExplodingState.value = "exploded";
      setIsPromptVisible(true);
    }
  });

  function showNextHint(hints: string[]) {
    typeItInstance.current?.unfreeze();

    typeItInstance.current
      ?.break()
      .break()
      .type(`>    ${hints[hints.length - 1]}`)
      .flush();
  }

  currentHintsSignal.subscribe((value) => {
    if (hintsGiven.current !== value.length) {
      hintsGiven.current = value.length;

      if (soundEffects.mainframePrompt.playing()) {
        soundEffects.mainframePrompt.once("pause", () => {
          showNextHint(value);
        });
      } else {
        showNextHint(value);
      }
    }
  });

  useEffect(() => {
    if (activeStep !== currentStep) {
      if (typeItInstance.current) {
        typeItInstance.current.freeze();
      }

      soundEffects.mainframePrompt.pause();

      hasCorrectAnswerMessageBeenSeen.value = false;

      currentHintsSignal.value = [];
      setActiveStep(currentStep);
    }
  }, [activeStep, currentStep]);

  useSignalEffect(() => {
    if (hasCorrectAnswerMessageBeenSeen.value === false) return;

    if (
      hasCorrectAnswerMessageBeenSeen.value === true &&
      typeItInstance.current?.is("frozen")
    ) {
      if (videoTransitionState.value === "done") {
        typeItInstance.current?.unfreeze();
      }
    }
  });

  return (
    <>
      <MainframeInner
        numberOfSteps={numberOfSteps}
        activeStep={activeStep}
        imagePath={imagePath}
      >
        {children}
      </MainframeInner>
      <>
        {prompt && (
          <div
            ref={promptRef}
            className={`c-prompt ${
              isPromptVisible ? "c-prompt--visible" : "c-prompt--hidden"
            }`}
          >
            <Scrollbar minScroll={0}>
              <TypeIt
                key={currentStep}
                as="div"
                id="mainframe-prompt"
                options={{
                  speed: 50,
                  waitUntilVisible: true,

                  beforeStep: () => {},
                  beforeString: () => {
                    if (
                      soundEffects.mainframePrompt.playing() === false &&
                      hasCorrectAnswerMessageBeenSeen.value === true
                    ) {
                      soundEffects.mainframePrompt.play();
                    }

                    if (typeItInstance.current) {
                      typeItInstance.current.unfreeze();
                    }
                  },
                  afterString: () => {
                    const elem = promptRef.current;
                    if (!elem) return;
                    elem.scrollTop = elem?.scrollHeight;
                    soundEffects.mainframePrompt.pause();
                  },

                  afterStep: () => {
                    setTimeout(() => {
                      const elem = promptRef.current;
                      if (!elem) return;
                      elem.scrollTop = elem?.scrollHeight;
                    }, 10);
                  },

                  afterComplete: () => {
                    soundEffects.mainframePrompt.pause();

                    if (typeItInstance.current) {
                      typeItInstance.current.freeze();
                    }

                    setTimeout(() => {
                      const elem = promptRef.current;
                      if (!elem) return;
                      elem.scrollTop = elem?.scrollHeight;
                    }, 10);
                  },
                }}
                getBeforeInit={(instance) => {
                  typeItInstance.current = instance;

                  // if (machineExplodingState.value !== "exploded") {
                  // }
                  typeItInstance.current.freeze();

                  return prompt(instance);
                }}
              />
            </Scrollbar>
          </div>
        )}
      </>
    </>
  );
};
