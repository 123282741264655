import { Howl } from "howler";
import "howler/src/plugins/howler.spatial";

import whooshFile from "@the-immersive/sound-effects/whoosh.mp3";
import clickEyeballFile from "@the-immersive/sound-effects/click-eyeball.mp3";
import computerNotificationFile from "@the-immersive/sound-effects/computer-notification.mp3";
import correctAnswerFile from "@the-immersive/sound-effects/correct-answer.mp3";
import wrongAnswerFile from "@the-immersive/sound-effects/error.mp3";
import gameTextReadoutFile from "@the-immersive/sound-effects/game-text-readout.mp3";
import machineFixedFile from "@the-immersive/sound-effects/machine-fixed.mp3";
// import shuttingDownFile from "@the-immersive/sound-effects/power-down.mp3";
// import selectFile from "@the-immersive/sound-effects/select.mp3";
import retroClickFile from "@the-immersive/sound-effects/retro-click.mp3";
// import submitFile from "@the-immersive/sound-effects/submission.mp3";
import weirdNotificationFile from "@the-immersive/sound-effects/weird-notification.mp3";
import droneHoverFile from "@the-immersive/sound-effects/drone-hover.mp3";

import modalOpeningFile from "@the-immersive/sound-effects/modal-opening.mp3";
import modalClosingFile from "@the-immersive/sound-effects/modal-closing.mp3";

import poweringDownFile from "@the-immersive/sound-effects/powering-down-deep.mp3";
import electricalFile from "@the-immersive/sound-effects/electricial-burst.mp3";

const magicClick = new Howl({
  src: [whooshFile],
  volume: 0.6,
  html5: false,
});

const metalicClick = new Howl({
  src: [clickEyeballFile],
  volume: 0.6,
});

const buttonClick = new Howl({
  src: [retroClickFile],
  volume: 0.6,
});

const normalNotification = new Howl({
  src: [computerNotificationFile],
  volume: 0.6,
});

const weirdNotification = new Howl({
  src: [weirdNotificationFile],
  volume: 0.6,
});

const wrongAnswer = new Howl({
  src: [wrongAnswerFile],
  volume: 0.6,
});

const correctAnswer = new Howl({
  src: [correctAnswerFile],
  volume: 0.6,
});

const textPrompt = new Howl({
  src: [gameTextReadoutFile],
  volume: 0.2,
  loop: true,
});

const logoHover = new Howl({
  src: [droneHoverFile],
  volume: 0.6,
  loop: true,
});

const mainframeFixed = new Howl({
  src: [machineFixedFile],
  volume: 0.6,
});

const sparks = new Howl({
  src: [electricalFile],
  volume: 0.6,
});

const mainframeShuttingDown = new Howl({
  src: [poweringDownFile],
  volume: 0.6,
});

const modalOpening = new Howl({
  src: [modalOpeningFile],
  volume: 0.3,
});

const modalClosing = new Howl({
  src: [modalClosingFile],
  volume: 0.3,
});

export const soundEffects = {
  loader: {
    click: magicClick,
    clickAlt: metalicClick,
    hover: logoHover,
  },

  answers: {
    correct: correctAnswer,
    incorrect: wrongAnswer,
  },

  buttonClick,

  notification: {
    normal: normalNotification,
    weird: weirdNotification,
  },

  mainframePrompt: textPrompt,
  mainframeShutdown: mainframeShuttingDown,
  mainframeFixed: mainframeFixed,
  mainframeSparks: sparks,

  modal: {
    opening: modalOpening,
    closing: modalClosing,
  },
};
