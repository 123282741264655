import "./art-deco-button.scss";

export type ArtDecoButtonProps = {
  children: React.ReactNode;
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  disabled?: boolean;
  style?: React.CSSProperties;
};

export const ArtDecoButton = ({
  children,
  onClick,
  disabled,
  style,
}: ArtDecoButtonProps) => (
  <button
    className="c-deco-button"
    disabled={disabled}
    onClick={onClick}
    style={style}
    type="submit"
  >
    <div className="c-deco-button__ornament c-deco-button__ornament--vertical">
      <div className="c-deco-button__ornament-vertical-top-bottom"></div>
      <div className="c-deco-button__ornament-vertical-left-right"></div>
    </div>

    <div className="c-deco-button__ornament c-deco-button__ornament--horizontal">
      <div className="c-deco-button__ornament-horizontal-top-bottom"></div>
      <div className="c-deco-button__ornament-horizontal-left-right"></div>

      <div className="c-deco-button__ornament-top-left"></div>
      <div className="c-deco-button__ornament-top-right"></div>
      <div className="c-deco-button__ornament-bottom-left"></div>
      <div className="c-deco-button__ornament-bottom-right"></div>
    </div>

    <span className="c-deco-button__label">{children}</span>
  </button>
);
