/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/ban-types */
import { PropsWithChildren, useState } from "react";
import {
  successMessages,
  machineExplodingState,
  useSignalEffect,
  currentStepNameSignal,
  isPopoverOpenSignal,
  isModalOpenSignal,
  correctAnswersSignal,
  globalStateSignal,
} from "@the-immersive/signals";
import {
  StepContainerComponentProps,
  Steps,
  useGlobalState,
} from "@guestbell/onboarder";
import {
  OnboarderState,
  StepperState,
  Puzzle_Steps_Enum,
  ExtraStepProps,
} from "@the-immersive/types";
import {
  Popover,
  AnswersPopover,
  Tooltip,
  TooltipContent,
  TooltipTrigger,
  Badge,
  IconButton,
  Modal,
  soundEffects,
  CCTVGrid,
  Mainframe,
  CircuitBoard,
} from "@the-immersive/components";
import answersIcon from "@the-immersive/icons/answer-log-icon.png";
import notesIcon from "@the-immersive/icons/system-notes-icon.png";
import cameraIcon from "@the-immersive/icons/security-camera-icon.png";

const fooLength = Object.keys(Puzzle_Steps_Enum).length - 2;
for (let i = 0; i <= fooLength; i++) {
  correctAnswersSignal.value[i] = "???";
}

export function FancyStepContainer<TState extends {}>(
  props: PropsWithChildren<StepContainerComponentProps<TState, ExtraStepProps>>
) {
  const { children, steps, currentStep } = props;

  const currentStepIndex = Object.keys(steps).findIndex(
    (p) => p === currentStep
  );

  // resetSignal.value = reset;

  const [explodingState, setExplodingState] = useState<
    | "has-not-exploded"
    | "is-exploding"
    | "has-exploded"
    | "is-repairing"
    | "is-repaired"
  >("has-exploded");

  useSignalEffect(() => {
    // console.log("machineExplodingState.value", machineExplodingState.value);

    if (
      machineExplodingState.value === "not-exploded" &&
      explodingState !== "has-not-exploded"
    ) {
      setExplodingState("has-not-exploded");
    } else if (
      machineExplodingState.value === "exploded" &&
      explodingState !== "has-exploded"
    ) {
      setExplodingState("has-exploded");

      // setTimeout(() => {
      //   // hasCorrectAnswerMessageBeenSeen.value = false;
      isPopoverOpenSignal.value = true;
      soundEffects.notification.normal.play();

      // console.log("final step?");
      // }, 400);
    } else if (
      machineExplodingState.value === "repairing" &&
      explodingState !== "is-repairing"
    ) {
      setExplodingState("is-repairing");
    } else if (
      machineExplodingState.value === "repaired" &&
      explodingState !== "is-repaired"
    ) {
      setExplodingState("is-repaired");
    }
  });

  const currentStepInstance = steps[currentStep];
  const globalState = useGlobalState<OnboarderState>();

  globalStateSignal.value = steps as Steps<OnboarderState, ExtraStepProps>;
  currentStepNameSignal.value = currentStep as keyof StepperState;

  Object.keys(steps).map((step, index) => {
    const step2 = step as keyof TState;
    const previousStep = steps[step2];
    if (
      index < currentStepIndex &&
      !successMessages.value.includes(previousStep.successMessage) &&
      previousStep.successMessage !== ""
    ) {
      successMessages.value.push(previousStep.successMessage);
    }
  });

  // useEffect(() => {
  //   const currentAnswer =
  //     globalState[currentStep as keyof OnboarderState].answer;
  //   let answerToStore;

  //   if (typeof currentAnswer === "object" && "username" in currentAnswer) {
  //     answerToStore = `${currentAnswer?.password}`;
  //   } else {
  //     answerToStore = currentAnswer;
  //   }

  //   // if (answerToStore !== undefined) {
  //   //   previousAnswers.value.push(answerToStore);
  //   // }
  // }, [globalState[currentStep as keyof OnboarderState].hasCorrectAnswer]);

  Object.keys(globalState).map((key, index) => {
    const previousStep = globalState[key as keyof OnboarderState];
    if (index < currentStepIndex) {
      if (
        previousStep.correctAnswers &&
        Array.isArray(previousStep.correctAnswers)
      ) {
        correctAnswersSignal.value[index] = `${previousStep.correctAnswers[0]}`;
      } else if (
        previousStep.correctAnswers &&
        !Array.isArray(previousStep.correctAnswers)
      ) {
        correctAnswersSignal.value[
          index
        ] = `${previousStep.correctAnswers.password}`;
      }
    }
  });

  return (
    <div
      className={`c-main c-main--step-${currentStepIndex} ${explodingState}`}
    >
      {currentStepNameSignal.value === Puzzle_Steps_Enum.FinalStep &&
        machineExplodingState.value === "repaired" && <CircuitBoard />}
      <header
        style={{
          opacity: currentStepInstance?.hideUi ? 0 : 1,
          display: "flex",
          position: "sticky",
          marginTop: "0rem",
          paddingLeft: "0.5rem",
          paddingRight: "0.5rem",
          marginBottom: "-52px",
          top: 0,
          zIndex: 200,
        }}
      >
        {currentStepNameSignal.value === Puzzle_Steps_Enum.Step6 ||
        currentStepNameSignal.value === Puzzle_Steps_Enum.Step9 ||
        currentStepNameSignal.value === Puzzle_Steps_Enum.Step13 ||
        (currentStepNameSignal.value === Puzzle_Steps_Enum.FinalStep &&
          machineExplodingState.value === "repaired") ? (
          <Tooltip open placement="bottom-start">
            <TooltipTrigger
              onClick={() => {
                isModalOpenSignal.value = true;
              }}
              className="c-icon-button"
            >
              <Badge>
                <img src={cameraIcon} width="44"></img>
              </Badge>
            </TooltipTrigger>
            <TooltipContent>
              {currentStepNameSignal.value === Puzzle_Steps_Enum.FinalStep
                ? "Motion detected"
                : "New feed detected"}
            </TooltipContent>
          </Tooltip>
        ) : (
          <IconButton
            onClick={() => {
              isModalOpenSignal.value = true;
            }}
          >
            <img src={cameraIcon} width="44"></img>
          </IconButton>
        )}
        <Modal>
          <CCTVGrid currentStep={currentStepNameSignal.value} />
        </Modal>

        <AnswersPopover
          triggerText="Answers"
          triggerIcon={answersIcon}
          style={{
            marginLeft: "auto",
          }}
        >
          {correctAnswersSignal.value.length > 0 ? (
            <ol>
              {correctAnswersSignal.value.map((message, index) => (
                <li
                  key={`${message}-${index}`}
                  style={{
                    border: "none",
                    paddingTop: "0.35rem",
                    paddingBottom: "0.35rem",
                  }}
                >
                  {message}
                </li>
              ))}
            </ol>
          ) : (
            <div>No System Messages</div>
          )}
        </AnswersPopover>

        <Popover
          triggerText="Messages"
          triggerIcon={notesIcon}
          onChange={() => {
            // console.log("onChange", args.isNowOpen);
          }}
        >
          {successMessages.value.length > 0 ? (
            <ul>
              {successMessages.value.map((message) => (
                <li
                  key={message}
                  dangerouslySetInnerHTML={{ __html: message }}
                />
              ))}
            </ul>
          ) : (
            <div>No System Messages</div>
          )}
        </Popover>
      </header>
      <div
        className="c-step-content"
        style={{
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
          backgroundColor: currentStepInstance.backgroundColor || "transparent",
          backgroundImage: currentStepInstance.backgroundImage || "none",
          backgroundSize: "1024px 1024px",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          maxWidth: "1024px",
          marginLeft: "auto",
          marginRight: "auto",
          width: "100%",
          paddingBottom: "2rem",
          justifyContent: "center",
          marginTop: "0rem",
          paddingLeft: "2rem",
          paddingTop: "2rem",
          paddingRight: "2rem",
          boxSizing: "border-box",
        }}
      >
        {!currentStepInstance?.hideUi && (
          <Mainframe
            imagePath={currentStepInstance.icon}
            prompt={currentStepInstance?.prompt ?? null}
            currentStep={currentStepIndex}
            numberOfSteps={Object.keys(steps).length - 1}
          >
            {currentStepInstance.mainframeChildren}
          </Mainframe>
        )}
        <>{children}</>
      </div>
    </div>
  );
}

export default FancyStepContainer;
