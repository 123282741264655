import { Client, fetchExchange, subscriptionExchange } from "urql";
// import { cacheExchange } from "@urql/exchange-graphcache";
// import schema from "./gql/graphql.schema.json";
// import { makeDefaultStorage } from "@urql/exchange-graphcache/default-storage";

import { createClient as createWSClient, SubscribePayload } from "graphql-ws";
import { getSdk } from "./generated/urql";

const wsClient = createWSClient({
  url: import.meta.env.GQL_WSS_ENDPOINT,
  connectionParams: () => {
    return {
      headers: {
        "x-hasura-admin-secret": import.meta.env.GQL_SECRET,
      },
    };
  },
});

// const storage = makeDefaultStorage({
//   idbName: "the-immsersive-v3", // The name of the IndexedDB database
//   maxAge: 30, // The maximum age of the persisted data in days
// });

export const client = new Client({
  url: import.meta.env.GQL_ENDPOINT,
  requestPolicy: "cache-first",
  exchanges: [
    // cacheExchange({
    //   schema,
    //   // storage,
    // }),
    fetchExchange,
    subscriptionExchange({
      forwardSubscription(operation) {
        return {
          subscribe: (sink) => {
            const dispose = wsClient.subscribe(
              operation as SubscribePayload,
              sink
            );
            return {
              unsubscribe: dispose,
            };
          },
        };
      },
    }),
  ],
  fetchOptions: () => {
    return {
      headers: {
        "x-hasura-admin-secret": import.meta.env.GQL_SECRET,
      },
    };
  },
});

export const urqlGql = getSdk(client);
