/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useRef } from "react";

import interact from "interactjs";

import runeGif from "@the-immersive/images/system-ui/circle-of-runes-animated.gif";

import { ScreenSize } from "@the-immersive/components";

// import "../../components/measure-display/lib/measure-display-view.css";
import "./runes.scss";
// import { ResizeEvent } from "@interactjs/types";
// import { sc, update } from "./demo.ts";

// const view = document.querySelector("#view");
// const ratioInput = document.querySelector("#ratio");
// const result = document.querySelector("#result");

// function mmToInches(x: number) {
//   return x / 25.4;
// }

// function diagonal(x: number, y: number) {
//   return Math.sqrt(x * x + y * y);
// }

// export

// ratioInput.addEventListener("input", () => {
//   sc.setRatio(ratioInput.value);
// });

export const Runes = () => {
  const previousValue = localStorage.getItem("measured-size");
  // const btn = document.getElementById("reset-button");
  const inputRef = useRef<HTMLInputElement>(null);
  const viewRef = useRef<HTMLDivElement>(null);
  const mainRef = useRef<HTMLDivElement>(null);
  const creditRef = useRef<HTMLDivElement>(null);
  // const resultRef = useRef<HTMLDivElement>(null);

  // function dragMoveListener(event: GestureEvent) {
  //   const target = event.target as HTMLElement;

  //   event.angle;
  //   // keep the dragged position in the data-x/data-y attributes
  //   const x = (parseFloat(target.getAttribute("data-x") || "") || 0) + event.dx;
  //   const y = (parseFloat(target.getAttribute("data-y") || "") || 0) + event.dy;

  //   // translate the element
  //   target.style.transform = "translate(" + x + "px, " + y + "px)";

  //   // update the posiion attributes
  //   target.setAttribute("data-x", x.toString());
  //   target.setAttribute("data-y", y.toString());

  //   if (sc.current) {
  //     console.log("ratio", sc.current.getRatio());
  //     // sc.current.setRatio(parseFloat(previousValue));
  //     update(sc.current.getRatio());

  //     // sc.current.setRatio(sc.current.getRatio());
  //   }
  // }

  useEffect(() => {
    if (!creditRef.current) return;

    interact(creditRef.current).resizable({
      // resize from all edges and corners
      edges: { left: true, right: true, bottom: true, top: true },
      margin: creditRef.current.getBoundingClientRect().width / 2,

      listeners: {
        move(event) {
          const target = event.target;
          let x = parseFloat(target.getAttribute("data-x")) || 0;
          let y = parseFloat(target.getAttribute("data-y")) || 0;

          // update the element's style
          // target.style.width = event.rect.width + "px";
          // target.style.height = event.rect.height + "px";

          // translate when resizing from top or left edges
          x += event.deltaRect.left;
          y += event.deltaRect.top;

          // target.style.transform = "translate(" + x + "px," + y + "px)";

          target.setAttribute("data-x", x);
          target.setAttribute("data-y", y);

          // event.rect.width

          // console.log(1 / (254 / (event.rect.width * 4)));
          // target.textContent =
          //   Math.round(event.rect.width) +
          //   "\u00D7" +
          //   Math.round(event.rect.height);

          if (sc.current) {
            // console.log(sc.current.getRatio());
            const newRatio = 1 / (254 / (event.rect.width * 4));
            sc.current.setRatio(newRatio);

            localStorage.setItem("measured-size", newRatio.toString());

            if (inputRef.current) {
              inputRef.current.value = newRatio.toString();
            }
            // update(sc.current.getRatio());
          }
        },
      },
      modifiers: [
        // keep the edges inside the parent
        // interact.modifiers.restrictEdges({
        //   outer: "parent",
        // }),

        // minimum size
        interact.modifiers.restrictSize({
          min: { width: 190, height: 190 },
          max: { width: 508, height: 508 },
        }),

        interact.modifiers.aspectRatio({ ratio: "preserve" }),
      ],

      inertia: true,
    });
    // .draggable({
    //   listeners: { move: dragMoveListener },
    //   inertia: true,
    //   modifiers: [
    //     interact.modifiers.restrictRect({
    //       restriction: "parent",
    //       endOnly: true,
    //     }),
    //     interact.modifiers.aspectRatio({ ratio: "preserve" }),
    //   ],
    // });
  });

  // update(sc.getRatio());

  // node: HTMLElement;
  // HTMLElement;
  // HTMLElement;

  // const sc

  const sc = useRef<ReturnType<typeof ScreenSize>>();

  function update(_ratio: number) {
    if (!sc.current) return;
    // const dimensions = sc.current.getDisplayDimensions();
    // console.log("dimensions", dimensions);

    // if (!resultRef.current) return;
    if (!creditRef.current) return;
    // resultRef.current.innerHTML = `${Math.round(
    //   dimensions.width
    // )}&thinsp;mm&nbsp;&times;&nbsp;${Math.round(
    //   dimensions.height
    // )}&thinsp;mm, diagonal ${mmToInches(
    //   diagonal(dimensions.width, dimensions.height)
    // ).toFixed(2)}&thinsp;in`;

    // const PPI =
    //   Math.sqrt(window.screen.height ** 2 + window.screen.width ** 2) /
    //   mmToInches(diagonal(dimensions.width, dimensions.height));

    // const widthInMM =
    //   (creditRef.current.getBoundingClientRect().width * PPI) / 2.54;
    // const heightInMM =
    // (creditRef.current.getBoundingClientRect().height / PPI) * 2.54;

    // const diagonal =
    //   (creditRef.current.getBoundingClientRect().width * 2.54) / 96;
    // console.log("widthInMM", width);
    // console.log(widthInMM - 85.6);

    // console.log(
    //   "calculated width in mm",
    //   (creditRef.current.getBoundingClientRect().width / PPI) * 25.4
    // );
    // console.log(
    //   "calculated height in mm",
    //   (creditRef.current.getBoundingClientRect().height / PPI) * 25.4
    // );
    // diagonal(dimensions.width, dimensions.height)
    // ratioInput.value = ratio;
  }

  useEffect(() => {
    if (viewRef.current && mainRef.current && creditRef.current) {
      sc.current = ScreenSize({
        node: viewRef.current,
        creditElem: creditRef.current,
        mainElem: mainRef.current,
        onChange: update,
        rulersOrientation: "horizontal",
      });
    }
  });

  useEffect(() => {
    if (previousValue && inputRef.current) {
      // console.log("previousValue", previousValue);
      inputRef.current.value = previousValue;
      inputRef.current.setAttribute("value", previousValue);

      if (sc.current) {
        sc.current.setRatio(parseFloat(previousValue));
        update(sc.current.getRatio());
      }
    }
  }, [previousValue, sc]);

  return (
    <div
      className="c-overlay-page"
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        flexGrow: 1,
      }}
    >
      <div
        id="view"
        ref={viewRef}
        style={{
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div className="measure-display-view horizontal-rulers" ref={mainRef}>
          <div
            className="c-resizable-image"
            ref={creditRef}
            style={{
              aspectRatio: "1 / 1",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              backgroundPosition: "center center",
              backgroundImage: `url(${runeGif})`,
              touchAction: "none",
              borderRadius: "50rem",
            }}
          >
            {/* <div className="chip"></div> */}
            {/* <img
              src={runeGif}
              style={{
                // pointerEvents: "none",
                aspectRatio: "1 / 1",
                width: "100%",
                userSelect: "none",
                touchAction: "none",
              }}
            ></img> */}
          </div>
          {/* <div className="rulers">
            <div className="left-ruler"></div>
            <div className="right-ruler interactive-ruler">
              <div className="drag-area"></div>
            </div>
            <div className="top-ruler"></div>
            <div className="bottom-ruler interactive-ruler">
              <div className="drag-area"></div>
            </div>
          </div> */}
        </div>
      </div>
      {/* <div id="result" ref={resultRef} style={{ color: "white" }}></div> */}

      <div
        id="control"
        style={{
          marginTop: "auto",
          // flexGrow: 1,
          display: "flex",
          alignItems: "end",
          padding: "1rem",
        }}
      >
        {/* <label htmlFor="ratio">Ratio:</label> */}
        <input
          type="range"
          ref={inputRef}
          id="ratio"
          name="volume"
          min="3"
          max="8"
          step="0.1"
          defaultValue="4"
          onChange={(e) => {
            localStorage.setItem("measured-size", e.target.value);
          }}
          onInput={(e) => {
            if (sc.current) {
              sc.current.setRatio(parseFloat(e.currentTarget.value));
            }
          }}
        />
        <button
          id="reset-button"
          className="c-prompt__submit"
          style={{
            marginBottom: "-12px",
            marginLeft: "16px",
            fontSize: "1.5rem",
            padding: "0.2rem 0.8rem",
            borderColor: "hsl(41 51% 30% / 1)",
            color: "hsl(41 51% 50% / 1)",
          }}
          type="button"
          onClick={() => {
            localStorage.removeItem("measured-size");

            if (inputRef.current) {
              inputRef.current.setAttribute("value", "4");
              inputRef.current.value = "4";
            }

            if (sc.current) {
              localStorage.removeItem("measured-size");

              sc.current.setRatio(4);
              update(sc.current.getRatio());
            }
          }}
        >
          Reset
        </button>
      </div>
    </div>
  );
};
