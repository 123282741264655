import "./badge.scss";

export const Badge = ({
  number = "!",
  children,
}: {
  children?: React.ReactNode;
  number?: string;
}) => {
  return (
    <div className="c-badge">
      {children}

      <div className="c-badge__item">{number}</div>
    </div>
  );
};
