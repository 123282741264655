export class Eye {
  private _pupil: HTMLElement;

  constructor(elem: HTMLDivElement | HTMLElement) {
    this._pupil = elem;
  }

  get pupil(): HTMLElement {
    return this._pupil;
  }
}

export class Pointer {
  constructor(eyes: Eye[]) {
    window.addEventListener("mousemove", (e) => this.move(e));

    this.eyes = eyes;
  }

  public width: number = 0;
  public height: number = 0;

  public eyes: Eye[] = [];

  move(e: MouseEvent): void {
    const mouseX: number = e.clientX;
    const mouseY: number = e.clientY;

    this.width = window.innerWidth;
    this.height = window.innerHeight;

    const distanceX: number = mouseX - this.width / 2;
    const distanceY: number = mouseY - this.height / 2;

    const distance: number = Math.sqrt(
      Math.pow(distanceX, 2) + Math.pow(distanceY, 2)
    );

    const rad: number = Math.atan2(distanceY, distanceX);

    const x: number = distance * Math.cos(rad);

    const y: number = distance * Math.sin(rad);

    for (let i = 0; i < this.eyes.length; i++) {
      this.eyes[i].pupil.style.transform =
        "translateX(" +
        Pointer.clamp(x / 20, -35, 35) +
        "px)" +
        "translateY(" +
        Pointer.clamp(y / 20, -35, 35) +
        "px)";
    }
  }

  stop() {
    window.removeEventListener("mousemove", (e) => this.move(e));
  }

  static clamp(number: number, min: number, max: number): number {
    return Math.max(min, Math.min(number, max));
  }
}

// const eyeLeft: Eye = new Eye(".eye-pupil-left");
// const eyes: Eye[] = [eyeLeft];
