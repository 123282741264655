/* eslint-disable @typescript-eslint/no-unused-vars */
import { FC, useState, useRef, useEffect } from "react";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import { useForm, SubmitHandler } from "react-hook-form";
import { useLoaderData, useNavigate, useLocation } from "react-router-dom";
import {
  LoaderParams,
  ExtraStepProps,
  StandardQuestion,
  OnboarderState,
  LoginFormInputs,
  StepNames,
  FancyProps,
} from "@the-immersive/types";
// import { Puzzle_Steps_Enum } from "../.../../../../../gql/urql";
import {
  machineExplodingState,
  isEyeballVisibleSignal,
  isPopoverOpenSignal,
  currentHintsSignal,
  videoTransitionState,
  currentStepNameSignal,
  globalStateSignal,
  successMessages,
  useSignalEffect,
} from "@the-immersive/signals";
import { urqlGql } from "@the-immersive/gql";
import { Wormhole, bgMusic, soundEffects } from "@the-immersive/components";

import Onboarder, {
  Steps,
  Structure,
  StepComponent,
  HookType,
} from "@guestbell/onboarder";
import { FancyStepContainer } from "./stepContainer/FancyStepContainer";

import "../../../../../packages/components/prompt/prompt.scss";
import { ArtDecoButton } from "./ArtDecoButton";

import eyeStarsIcon from "@the-immersive/images/mainframe/eye-stars.png";
import flashlightIcon from "@the-immersive/images/mainframe/flashlight.png";
import inkBottleIcon from "@the-immersive/images/mainframe/ink-bottle.png";
import letterheadIcon from "@the-immersive/images/mainframe/letterhead.png";

import listIcon from "@the-immersive/images/mainframe/list.png";

import polybiusIcon from "@the-immersive/images/mainframe/polybius.png";

import postcardIcon from "@the-immersive/images/mainframe/postcard.png";
import postcardsIcon from "@the-immersive/images/mainframe/postcards.png";

import questionMarkIcon from "@the-immersive/images/mainframe/question-mark.png";

import runesIcon from "@the-immersive/images/mainframe/runes.png";
import magnifyingGlassIcon from "@the-immersive/images/mainframe/magnifying-glass.png";
import stampIcon from "@the-immersive/images/mainframe/stamp.png";
import welcomeIcon from "@the-immersive/images/mainframe/welcome.png";

import loginBackground from "@the-immersive/backgrounds/login-pageA.png";

const defaultQuestionState: Omit<StandardQuestion, "correctAnswers"> = {
  continueButtonText: "Check Answer",
  answer: undefined,
  isDirty: false,
  maxAttempts: 3,
  numberOfAttempts: 0,
  numberOfHintsGiven: 0,
  hints: ["hint #1", "hint #2", "final hint"],
  hasCorrectAnswer: undefined,
  maxInputLength: 8,
};

const finalStageNextHook: HookType<
  OnboarderState,
  StandardQuestion,
  boolean
> = ({ state, setState }) => {
  if (state.answer && state.correctAnswers.includes(state.answer)) {
    if (state.hasCorrectAnswer) {
      // soundEffects.answers.correct.play();
      return true;
    } else {
      setState({
        ...state,
        answer: state.answer,
        hasCorrectAnswer: true,
        errorMessage: undefined,
      });

      // isPopoverOpenSignal.value = true;
      // soundEffects.notification.normal.play();

      return true;
    }
  }

  setState({
    ...state,
    hasCorrectAnswer: false,
  });
  return false;
};

const defaultBeforeNextHook: HookType<
  OnboarderState,
  StandardQuestion,
  boolean
> = ({ state, setState }) => {
  if (state.answer && state.correctAnswers.includes(state.answer)) {
    if (state.hasCorrectAnswer) {
      // soundEffects.answers.correct.play();
      return true;
    } else {
      setState({
        ...state,
        answer: state.answer,
        hasCorrectAnswer: true,
        errorMessage: undefined,
      });

      isPopoverOpenSignal.value = true;
      soundEffects.notification.normal.play();

      return true;
    }
  }

  setState({
    ...state,
    hasCorrectAnswer: false,
  });
  return false;
};

const StandardQuestionUI: StepComponent<
  OnboarderState,
  StandardQuestion
  // {}
> = ({ setState, state, goToNextStep }) => {
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (state.hasCorrectAnswer && inputRef.current) {
      inputRef.current.value = "";
    } else if (inputRef.current && !state.isDirty && inputRef.current.value) {
      inputRef.current.value = "";
    }
  }, [state.hasCorrectAnswer, state.isDirty]);

  useEffect(() => {
    if (inputRef.current && state.hasCorrectAnswer === false) {
      inputRef.current.classList.add("c-prompt--error");
      soundEffects.answers.incorrect.play();
      setTimeout(() => {
        if (inputRef.current) {
          inputRef.current.classList.remove("c-prompt--error");
        }
      }, 1000);
    } else if (inputRef.current && state.hasCorrectAnswer === true) {
      soundEffects.answers.correct.play();
      videoTransitionState.value = "not-started";
    }
  });

  return (
    <div
      style={{
        textAlign: "center",
        maxWidth: "480px",
        marginLeft: "auto",
        marginRight: "auto",
        position: "relative",
        display: "flex",
        width: "100%",
        marginTop: "1rem",
        gap: "1rem",
        flexFlow: "row wrap",
      }}
    >
      <input
        type="text"
        placeholder="your answer..."
        ref={inputRef}
        id="answer-input"
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            if (e.currentTarget.value.toLowerCase().includes("i need help")) {
              if (inputRef.current) {
                inputRef.current.value = "";
              }

              if (state.numberOfHintsGiven < state.hints.length) {
                if (
                  !currentHintsSignal.value.includes(
                    state.hints[state.numberOfHintsGiven]
                  )
                ) {
                  currentHintsSignal.value.push(
                    state.hints[state.numberOfHintsGiven]
                  );
                }

                setState({
                  ...state,
                  hasCorrectAnswer: undefined,
                  numberOfHintsGiven: state.numberOfHintsGiven + 1,
                });
              } else {
                if (inputRef.current) {
                  inputRef.current.classList.add("c-prompt--error");
                  soundEffects.answers.incorrect.play();
                  setTimeout(() => {
                    if (inputRef.current) {
                      inputRef.current.classList.remove("c-prompt--error");
                    }
                  }, 1000);
                }
              }
            } else if (e.currentTarget.value.toLowerCase() === "") {
              if (inputRef.current) {
                inputRef.current.classList.add("c-prompt--error");
                soundEffects.answers.incorrect.play();
                setTimeout(() => {
                  if (inputRef.current) {
                    inputRef.current.classList.remove("c-prompt--error");
                  }
                }, 1000);
              }
            } else {
              setState({
                ...state,
                answer:
                  e.currentTarget.value.toUpperCase().replaceAll(",", "") || "",
                hasCorrectAnswer: undefined,
                isDirty: true,
              });
              goToNextStep();
            }
          }
        }}
        onInput={(e) => {
          if (
            e.currentTarget.value.toLowerCase() !== "" &&
            !e.currentTarget.value.toLowerCase().trim().includes("i need help")
          ) {
            setState({
              ...state,
              hasCorrectAnswer: undefined,
              answer:
                e.currentTarget.value.toUpperCase().replaceAll(",", "") || "",
              isDirty: true,
            });
          }
        }}
        onBlur={(e) => {
          if (
            e.currentTarget.value.toLowerCase().trim().includes("i need help")
          ) {
            if (inputRef.current) {
              inputRef.current.value = "";
            }

            if (state.numberOfHintsGiven < state.hints.length === false) {
              if (inputRef.current) {
                inputRef.current.classList.add("c-prompt--error");
                soundEffects.answers.incorrect.play();
                setTimeout(() => {
                  if (inputRef.current) {
                    inputRef.current.classList.remove("c-prompt--error");
                  }
                }, 1000);
              }
            }
          }
        }}
        className="c-prompt c-prompt__input"
      ></input>
      <button
        className="c-prompt__submit"
        onClick={() => {
          if (
            inputRef.current &&
            inputRef.current.value.toLowerCase().trim().includes("i need help")
          ) {
            if (state.numberOfHintsGiven < state.hints.length) {
              if (
                !currentHintsSignal.value.includes(
                  state.hints[state.numberOfHintsGiven]
                )
              ) {
                currentHintsSignal.value.push(
                  state.hints[state.numberOfHintsGiven]
                );
              }

              setState({
                ...state,
                hasCorrectAnswer: undefined,

                numberOfHintsGiven: state.numberOfHintsGiven + 1,
              });

              inputRef.current.value = "";
            } else {
              inputRef.current.classList.add("c-prompt--error");
              soundEffects.answers.incorrect.play();
              setTimeout(() => {
                if (inputRef.current) {
                  inputRef.current.classList.remove("c-prompt--error");
                }
              }, 1000);
            }
          } else if (inputRef.current && inputRef.current.value === "") {
            inputRef.current.classList.add("c-prompt--error");
            soundEffects.answers.incorrect.play();
            setTimeout(() => {
              if (inputRef.current) {
                inputRef.current.classList.remove("c-prompt--error");
              }
            }, 1000);
          } else {
            goToNextStep();
          }
        }}
      >
        Submit
      </button>
      <p className="c-prompt__helper-text">
        type “I need help” for further assistance
      </p>
      {state.errorMessage && (
        <Alert severity="error">{state.errorMessage}</Alert>
      )}
    </div>
  );
};

const steps: Steps<OnboarderState, ExtraStepProps> = {
  [StepNames.Step1]: {
    timeRequiredSec: 1,
    backgroundColor: "transparent",
    backgroundImage: `url(${loginBackground})`,
    Component: ({ goToNextStep, setState, state }) => {
      useSignalEffect(() => {
        if (
          currentStepNameSignal.value &&
          currentStepNameSignal.value === StepNames["Step1"] &&
          !isEyeballVisibleSignal.value
        ) {
          // bgMusic.login.fade(0, 0.3, 300).play();
        } else {
          // bgMusic.login.fade(0.3, 0, 300).pause();
          // bgMusic.loader.fade(0.3, 0, 300);
        }
      });

      const { register, handleSubmit } = useForm<LoginFormInputs>();
      const [userName, setUserName] = useState<string>("");
      const [password, setPassword] = useState<string>("");

      useEffect(() => {
        setState({
          ...state,
          answer: {
            username: userName.toLowerCase().trim(),
            password: password.toLowerCase().trim(),
          },
          isDirty: true,
        });
      }, [userName, password, state.answer.password, state.answer.username]);

      const onSubmit: SubmitHandler<LoginFormInputs> = async (
        _data,
        _event
      ) => {
        // console.log("onsubmit", formState);
        setState({
          ...state,
          answer: {
            username: userName,
            password: password,
          },
          isDirty: true,
        });

        goToNextStep();
      };

      return (
        <>
          {state.errorMessage && (
            <Alert
              severity="error"
              style={{
                position: "absolute",
                marginLeft: "-.5rem",
                marginRight: "1.5rem",
                marginBottom: "-27.5rem",
                left: "50%",
                transform: "translateX(-50%)",
              }}
            >
              {state.errorMessage}
            </Alert>
          )}

          <form
            onSubmit={handleSubmit(onSubmit)}
            name="lastpass-disable-search"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "end",
              alignItems: "center",
              minHeight: "800px",
              maxHeight: "800px",
              paddingBottom: "44px",
              flexGrow: 1,
            }}
          >
            <input
              type="email"
              placeholder="username..."
              className="c-text-input"
              autoComplete="off"
              data-lpignore="true"
              data-1p-ignore="true"
              required
              {...register("username", {
                required: true,
              })}
              onFocus={() => {
                setState({
                  ...state,
                  errorMessage: undefined,
                });
              }}
              style={{
                backgroundColor: "transparent",
                color: "#ffe97f",
                textShadow: "0 0 1px #c59b4a",
                fontSize: "28px",
                border: "none",
                textTransform: "uppercase",
                lineHeight: "1.2",
                fontFamily: "breamcatcher",
                marginBottom: "20px",
                width: "320px",
                paddingLeft: ".75rem",
                paddingRight: ".75rem",
                boxSizing: "border-box",
                paddingTop: "1.5rem",
                paddingBottom: "1.5rem",
              }}
              onInput={(e) => {
                setUserName(e.currentTarget.value.trim().toLowerCase());
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  if (e.currentTarget.value === "" || !e.currentTarget.value) {
                    soundEffects.answers.incorrect.play();
                  }
                }
              }}
            ></input>

            <input
              type="password"
              placeholder="password..."
              className="c-text-input"
              autoComplete="off"
              data-lpignore="true"
              data-1p-ignore="true"
              required
              onFocus={() => {
                setState({
                  ...state,
                  errorMessage: undefined,
                });
              }}
              onInput={(e) => {
                setPassword(e.currentTarget.value.trim().toLowerCase());
              }}
              {...register("password", {
                required: true,
              })}
              style={{
                backgroundColor: "transparent",
                color: "#ffe97f",
                textShadow: "0 0 1px #c59b4a",
                fontSize: "28px",
                textTransform: "uppercase",
                lineHeight: "1.2",
                fontFamily: "breamcatcher",
                padding: "4px .75rem",
                border: "none",
                boxSizing: "border-box",
                width: "320px",
                marginBottom: "144px",
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  if (e.currentTarget.value === "" || !e.currentTarget.value) {
                    soundEffects.answers.incorrect.play();
                  }
                }
              }}
            ></input>

            <ArtDecoButton
              onClick={() => {
                if (!userName || !password) {
                  soundEffects.answers.incorrect.play();
                }
              }}
            >
              Log In
            </ArtDecoButton>
          </form>
        </>
      );
    },
    title: "Login",
    successMessage:
      "Oh great! We're both logged in at the same time - I can leave notes here on the dashboard to communicate with you. <br /><br /> All experiences are a web of interconnectedness. The magic of our theater depends on all the right threads being connected. If too many are broken, the experience suffers and things fall apart.",
    hideUi: true,
    beforeNext: ({ state, setState }) => {
      if (!state.answer.username) {
        setState({
          ...state,
          errorMessage: "Please enter a username!",
        });
        soundEffects.answers.incorrect.play();
        return false;
      }

      if (!state.answer.password) {
        setState({
          ...state,
          errorMessage: "Please enter a password!",
        });
        soundEffects.answers.incorrect.play();
        return false;
      }

      if (
        state.correctAnswers.username !==
        state.answer.username.toLowerCase().trim()
      ) {
        setState({
          ...state,
          errorMessage: "Invalid username",
        });
        soundEffects.answers.incorrect.play();
        return false;
      }

      if (
        state.correctAnswers.password !==
        state.answer.password.toLowerCase().trim()
      ) {
        setState({
          ...state,
          errorMessage: "Invalid password",
        });
        soundEffects.answers.incorrect.play();
        return false;
      }

      if (globalStateSignal.value && currentStepNameSignal.value) {
        const currentStepData =
          globalStateSignal.value[currentStepNameSignal.value];

        successMessages.value.push(currentStepData.successMessage);

        soundEffects.buttonClick.play();
      }

      // switchClickEffect.play();

      return true;
    },
    afterNext: ({ state, setState }) => {
      setState({
        ...state,
        isDirty: false,
        errorMessage: undefined,
        hasCorrectAnswer: undefined,
        numberOfHintsGiven: 0,
      });
    },
    initialState: {
      ...defaultQuestionState,
      correctAnswers: {
        username: "gparados@theimmersivetheater.com",
        password: "the fourth wall",
      },
      answer: {
        username: "",
        password: "",
      },
      isDirty: false,
      hasCorrectAnswer: undefined,
      maxAttempts: 3,
      numberOfAttempts: 0,
      continueButtonText: "test",
      errorMessage: undefined,
      maxInputLength: undefined,
    },
  },
  [StepNames.Step2]: {
    icon: magnifyingGlassIcon,
    prompt: (instance) => {
      return instance
        .type("Customer experience malfunction overload.")
        .break()
        .type("Theater out of alignment.")
        .break()
        .type("Experiences unstable.")
        .pause(2000)
        .break()
        .break()
        .type(
          "Restore operation by identifying relationships for what went wrong."
        )
        .break()
        .type(
          "Enter the <strong>Producer's</strong> key word to begin realignment process."
        )
        .go();
    },
    successMessage:
      "I know I made a typo on the ticket but that's not enough to trap me in here. There must be much bigger problems we need to identify. Let's see what the theater system suggests...",
    Component: StandardQuestionUI,
    initialState: {
      ...defaultQuestionState,
      maxAttempts: 6,
      correctAnswers: ["CONNECTION"],
      hints: [
        "Look closely at the Welcome Letter",
        "Ten of the letters have something in common",
        "Combine all the underlined letters",
      ],
    },
    afterNext: ({ state, setState }) => {
      setState({
        ...state,
        answer: undefined,
        isDirty: false,
        errorMessage: undefined,
        continueButtonText: undefined,
        hasCorrectAnswer: undefined,
        numberOfHintsGiven: 0,
      });
    },
    beforeNext: defaultBeforeNextHook,
    title: "Loop step",
    timeRequiredSec: 25,
  },
  [StepNames.Step3]: {
    prompt: (instance) => {
      return instance
        .type(
          "A ticket package was not properly authenticated when sealed. Something got in the way. Enter the authentication number."
        )
        .go();
    },
    successMessage:
      "I was in such a rush I didn't even notice Theo's card in the way when I stamped the envelope. The theater is logging a number of issues with your experience. ",
    icon: stampIcon,
    initialState: {
      ...defaultQuestionState,
      hints: [
        "The package should be stamped with an authentication number.",
        "The number is 4 digits.",
        "Check for both halves of the stamp on your materials.",
      ],
      correctAnswers: ["0216", "#0216", "0 2 1 6", "02 16"],
    },
    Component: StandardQuestionUI,
    beforeNext: defaultBeforeNextHook,
    afterNext: ({ state, setState }) => {
      setState({
        ...state,
        answer: undefined,
        isDirty: false,
        errorMessage: undefined,
        continueButtonText: undefined,
        hasCorrectAnswer: undefined,
        numberOfHintsGiven: 0,
      });
    },
  },
  [StepNames.Step4]: {
    prompt: (instance) => {
      return instance
        .type(
          "The Producer was supposed to mention the birth of our art in the welcome letter, but the year appears to be missing."
        )
        .go();
    },
    successMessage:
      "I was supposed to proofread everything against our brand guidelines. I guess he did add the founding date in his own way. During wartime, art deco theaters exploded in popularity as pure escapism. But immersive experiences date back at least to the paleolithic period.",
    icon: welcomeIcon,
    initialState: {
      ...defaultQuestionState,
      hints: [
        "Is there anything odd about any of the text in his letter?",
        "Some of the words in his letter don't look like numbers, but they do sound like them.",
        'The year is 4 digits starting with 1 ("won")',
      ],
      correctAnswers: ["1942"],
    },
    Component: StandardQuestionUI,
    beforeNext: defaultBeforeNextHook,
    afterNext: ({ state, setState }) => {
      setState({
        ...state,
        answer: undefined,
        isDirty: false,
        errorMessage: undefined,
        continueButtonText: undefined,
        hasCorrectAnswer: undefined,
        numberOfHintsGiven: 0,
      });
    },
  },
  [StepNames.Step5]: {
    prompt: (instance) => {
      return instance
        .type(
          "Supplies should have been updated in the inventory spreadsheet to support *something*..."
        )
        .go();
    },
    successMessage:
      "I'll update that spreadsheet right now so we can operate the new games! Always so much to stay on top of. I don't know how Theo does it. They were name-called \"four eyes\" as a young kid but that discounts the 5th one in the back of their head.",
    icon: listIcon,
    initialState: {
      ...defaultQuestionState,
      correctAnswers: ["GAMES"],
      hints: [
        "Check the first line of the supplies list in the email thread. There is a 5 letter word encoded in this list.",
        "Compare the first line's number to the letter in bold...",
        "For the first line starting with 3, G is the 3rd letter on the line.",
      ],
    },
    Component: StandardQuestionUI,
    beforeNext: defaultBeforeNextHook,
    afterNext: ({ state, setState }) => {
      setState({
        ...state,
        answer: undefined,
        isDirty: false,
        errorMessage: undefined,
        continueButtonText: undefined,
        hasCorrectAnswer: undefined,
        numberOfHintsGiven: 0,
      });
    },
  },
  [StepNames.Step6]: {
    prompt: (instance) => {
      return instance
        .type(
          "Normally the Stage Manager fixes these things. Try seeing things through their eyes to understand why they aren't helping this time."
        )
        .go();
    },
    successMessage:
      "Theo's so smart and organized and hard working and they always know what to do and... They deserve the break. I just miss them. This job is hard. Especially when you get trapped in interdimensional spaces.",
    icon: eyeStarsIcon,
    initialState: {
      ...defaultQuestionState,
      correctAnswers: ["VACAY"],
      hints: [
        "The new imagery piped through the camera system is resizeable.",
        "It's easier to see through Theo's eyes if you have a pen, screwdriver, or holepunch.",
        "Find the 5 eyes on Theo's business card.",
      ],
    },
    Component: StandardQuestionUI,
    beforeNext: defaultBeforeNextHook,
    afterNext: ({ state, setState }) => {
      setState({
        ...state,
        answer: undefined,
        isDirty: false,
        errorMessage: undefined,
        continueButtonText: undefined,
        hasCorrectAnswer: undefined,
        numberOfHintsGiven: 0,
      });
    },
  },
  [StepNames.Step7]: {
    prompt: (instance) => {
      return instance
        .type(
          "According to the print template, the street number was not added. But if you saw these performers from the very back of the theater, you might still get the message."
        )
        .go();
    },
    successMessage:
      "Yikes! I'll need to run a new order of these postcards with the full address. Even Theo missed that and Theo's basically perfect.",
    icon: postcardIcon,
    initialState: {
      ...defaultQuestionState,
      maxInputLength: undefined,
      hints: [
        "Sometimes you have to squint from a distance to see the bigger picture.",
        "Try looking for a number by looking at the postcard from a distance. Maybe from across the room even.",
        "The number is part of the photograph itself. Try squinting.",
      ],
      correctAnswers: [
        "63",
        "63 EXCHANGE ST",
        "63 EXCHANGE STREET",
        "63 EXCHANGE ST, MALDEN, MA",
        "63 EXCHANGE STREET, MALDEN MA",
        "63 EXCHANGE STREET",
        "42",
      ],
    },
    Component: StandardQuestionUI,
    beforeNext: defaultBeforeNextHook,
    afterNext: ({ state, setState }) => {
      setState({
        ...state,
        answer: undefined,
        isDirty: false,
        errorMessage: undefined,
        continueButtonText: undefined,
        hasCorrectAnswer: undefined,
        numberOfHintsGiven: 0,
      });
    },
  },
  [StepNames.Step8]: {
    prompt: (instance) => {
      return instance
        .type(
          "Someone spilled ink all over. Something should have been done before stuffing envelopes."
        )
        .go();
    },
    successMessage:
      "I got up to clean ink off myself and when I got back everything was dried. I didn't even notice the back of the postcard was ruined. I should have cleaned up the workspace fully.",

    icon: inkBottleIcon,
    initialState: {
      ...defaultQuestionState,
      correctAnswers: ["CLEAN"],
      hints: [
        "This postcard is best appreciated from different perspectives. ",
        "Last time you looked dead on from far away with two eyes; this time try the opposite.",
        "Close one eye and tilt the top of the card away from you so you're mostly looking at the bottom edge. Try reading the hidden message in the ink now.",
      ],
    },
    Component: StandardQuestionUI,
    beforeNext: defaultBeforeNextHook,
    afterNext: ({ state, setState }) => {
      setState({
        ...state,
        answer: undefined,
        isDirty: false,
        errorMessage: undefined,
        continueButtonText: undefined,
        hasCorrectAnswer: undefined,
        numberOfHintsGiven: 0,
      });
    },
  },
  [StepNames.Step9]: {
    prompt: (instance) => {
      return instance
        .type(
          "There’s a problem with the digital version of the postcard. Something doesn’t match."
        )
        .go();
    },
    successMessage:
      "The webmaster didn't update the postcard image with the final approved version. I'll replace it with the correct one now!",
    icon: postcardsIcon,
    initialState: {
      ...defaultQuestionState,
      correctAnswers: ["THIS", "THAT", "A WORD"],
      hints: [
        "You'll need your paper version of the postcard.",
        "It's something to do with the postcard as printed - not the ink that was spilled.",
        "Compare the content of the image of the postcard with the physical postcard in hand. Write what is different on the photo version.",
      ],
    },
    Component: StandardQuestionUI,
    beforeNext: defaultBeforeNextHook,
    afterNext: ({ state, setState }) => {
      setState({
        ...state,
        answer: undefined,
        isDirty: false,
        errorMessage: undefined,
        continueButtonText: undefined,
        hasCorrectAnswer: undefined,
        numberOfHintsGiven: 0,
      });
    },
  },
  [StepNames.Step10]: {
    prompt: (instance) => {
      return instance
        .type("Something isn’t right about the letterhead design either...")
        .go();
    },
    successMessage:
      "I was so short on paper I thought it'd be ok since it didn't get crinkled up. In my defense... this was a very bad day for me what with Theo leaving for months. I appreciate your help here.",
    icon: letterheadIcon,
    initialState: {
      ...defaultQuestionState,
      correctAnswers: [
        "PRINTER JAM",
        "JAM",
        "PRINT JAM",
        "PRINTING ISSUE",
        "PRINTER",
        "PRINT ISSUE",
        "PAPER JAM",
      ],
      hints: [
        "The letterhead has a weird, inconsistent border. How did that happen?",
        "Looks like you could fold or cut the paper to restore the broken pattern.",
        "When properly aligned, the pattern looks like symbols of some sort.",
      ],
    },
    Component: StandardQuestionUI,
    beforeNext: defaultBeforeNextHook,
    afterNext: ({ state, setState }) => {
      setState({
        ...state,
        answer: undefined,
        isDirty: false,
        errorMessage: undefined,
        continueButtonText: undefined,
        hasCorrectAnswer: undefined,
        numberOfHintsGiven: 0,
      });
    },
  },
  [StepNames.Step11]: {
    prompt: (instance) => {
      return instance
        .type(
          "The back of the envelope reveals another problem with the welcome letter."
        )
        .go();
    },
    successMessage:
      "I was out of paper but needed to get your ticket out. I figured printing on scrap paper was better than delaying delivery by a whole day. The theater disagrees. I see why Theo is always so careful here.",
    icon: polybiusIcon,
    initialState: {
      ...defaultQuestionState,
      correctAnswers: ["SCRAP", "SCRAP PAPER"],
      hints: [
        "The numbers have something to do with the table drawn on the back of the letterhead.",
        "You sunk my battleship!",
        "The first number is 43, and the first letter is S.",
      ],
    },
    Component: StandardQuestionUI,
    beforeNext: defaultBeforeNextHook,
    afterNext: ({ state, setState }) => {
      setState({
        ...state,
        answer: undefined,
        isDirty: false,
        errorMessage: undefined,
        continueButtonText: undefined,
        hasCorrectAnswer: undefined,
        numberOfHintsGiven: 0,
      });
    },
  },
  [StepNames.Step12]: {
    prompt: (instance) => {
      return instance
        .type(
          "The envelope was mailed late. What's the timestamp for when the envelope was sealed?"
        )
        .go();
    },
    successMessage:
      "Theo said mail pickup was 11am but I didn't get this one together till noon. So it was a day late anyway. Oof. If it's any consolation, I'm the one trapped in a weird liminal space.",
    icon: flashlightIcon,
    initialState: {
      ...defaultQuestionState,
      correctAnswers: [
        "NOON",
        "12:00",
        "12 pm",
        "12:00 PM",
        "12pm",
        "12PM",
        "12 o clock",
      ],
      hints: [
        "The timestamp hides in the shadows.",
        "Grab a flashlight.",
        "Touch the flashlight directly to the envelope. Search the recesses.",
      ],
    },
    Component: StandardQuestionUI,
    beforeNext: defaultBeforeNextHook,
    afterNext: ({ state, setState }) => {
      setState({
        ...state,
        answer: undefined,
        isDirty: false,
        errorMessage: undefined,
        continueButtonText: undefined,
        hasCorrectAnswer: undefined,
        numberOfHintsGiven: 0,
      });
    },
  },
  [StepNames.Step13]: {
    prompt: (instance) => {
      return instance
        .type(
          "Dim the lights and put your thinking cap on; why was the alignment cipher included?"
        )
        .go();
    },
    successMessage:
      "I didn't realize I grabbed the wrong item! Those ciphers on the desk were supposed to be used for something else? So what was I supposed to put in there instead?",
    icon: runesIcon,
    initialState: {
      ...defaultQuestionState,
      correctAnswers: ["SWAPPED"],
      hints: [
        "The cipher runes look a lot like letters - starting from the bottom dash clockwise there appears to be a T, W, L, N, and so on...",
        "Turn your brightness up and your lights down.",
        "Place the alignment cipher on the screen and adjust the size and position to match.",
      ],
    },
    Component: StandardQuestionUI,
    beforeNext: defaultBeforeNextHook,
    afterNext: ({ state, setState }) => {
      setState({
        ...state,
        answer: undefined,
        isDirty: false,
        errorMessage: undefined,
        continueButtonText: undefined,
        hasCorrectAnswer: undefined,
        numberOfHintsGiven: 0,
      });
    },
  },
  [StepNames.MissingItem]: {
    prompt: (instance) => {
      return instance.type("Something is missing from the envelope.").go();
    },
    icon: questionMarkIcon,
    initialState: {
      ...defaultQuestionState,
      correctAnswers: ["EYE LAPEL PIN", "LAPEL PIN", "PIN", "BUTTON"],
      hints: [
        "Go back to the beginning of your journey.",
        "There's a list of everything that should have been included.",
        "Re-read the letterhead.",
      ],
    },
    successMessage:
      "The door re-appeared! I am out of Theater 10! Thank you for heping me fix my mistakes! I hope someone returns the favor next time you have a bad day. I will get your pin ready for when you arrive. See you soon! (please don't tell Theo about this!)",
    Component: StandardQuestionUI,
    beforeNext: finalStageNextHook,
    afterNext: ({ state, setState }) => {
      setState({
        ...state,
        answer: undefined,
        isDirty: false,
        errorMessage: undefined,
        continueButtonText: undefined,
        hasCorrectAnswer: undefined,
        numberOfHintsGiven: 0,
      });
    },
  },

  [StepNames.FinalStep]: {
    title: "Final step",
    successMessage:
      "Your ticket has more secrets to unlock. Book your experience at The Immersive to find your missing item.",
    icon: undefined,
    Component: ({ currentStep }) => {
      useSignalEffect(() => {
        if (
          currentStepNameSignal.value &&
          currentStepNameSignal.value === currentStep &&
          !isEyeballVisibleSignal.value
        ) {
          if (
            machineExplodingState.value !== "repairing" &&
            machineExplodingState.value !== "repaired"
          ) {
            soundEffects.mainframeFixed.play();
            machineExplodingState.value = "repairing";
          } else if (machineExplodingState.value === "repaired") {
            setTimeout(() => {
              soundEffects.notification.normal.play();
              bgMusic.finale.play();
            }, 2000);
          }
        }
      });

      return (
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          minHeight="100%"
          className="c-mainframe--wut"
        >
          <p
            className="c-prompt"
            style={{
              marginBottom: "4rem",
              backgroundColor: "rgba(0, 0, 0, .6)",
            }}
          >
            Your ticket has more secrets to unlock. Book your experience at The
            Immersive to find your missing item.
          </p>

          <ArtDecoButton
            onClick={() => {
              window.open(
                "https://www.theimmersivetheater.com/box-office",
                "_blank"
              );
            }}
            style={{
              backgroundColor: "rgba(0, 0, 0, .6)",
            }}
          >
            Book Now
          </ArtDecoButton>
        </Box>
      );
    },
    mainframeChildren: <Wormhole />,
    hideUi: false,
    timeRequiredSec: 13,
  },
};

const finalSteps: (keyof OnboarderState)[] = [StepNames.FinalStep];

const structure: Structure<OnboarderState> = {
  [StepNames.Step1]: () => ({ step2: 1 }),
  step2: () => ({ step3: 1 }),
  step3: () => ({ step4: 1 }),
  step4: () => ({ step5: 1 }),
  step5: () => ({ step6: 1 }),
  step6: () => ({ step7: 1 }),
  step7: () => ({ step8: 1 }),
  step8: () => ({ step9: 1 }),
  step9: () => ({ step10: 1 }),
  step10: () => ({ step11: 1 }),
  step11: () => ({ step12: 1 }),
  step12: () => ({ step13: 1 }),
  step13: () => ({ [StepNames.MissingItem]: 1 }),
  [StepNames.MissingItem]: () => ({ finalStep: 1 }),
  finalStep: undefined,
};

export const Fancy: FC<FancyProps> = ({ debug }) => {
  const params = useLoaderData() as LoaderParams;

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (
      location.pathname !== `/${params.playerId}/${params.currentStep}` &&
      location.pathname !== `/${params.playerId}/${currentStepNameSignal.value}`
    ) {
      navigate(`/${params.playerId}/${params.currentStep}`, {
        unstable_viewTransition: true,
        replace: true,
      });
    }
  });

  useEffect(() => {
    urqlGql
      .playerProgressSubscription({
        playerId: params.playerId,
      })
      .subscribe(({ data }) => {
        if (
          data?.player_sessions_by_pk?.currentStep !== undefined &&
          data?.player_sessions_by_pk?.currentStep !==
            currentStepNameSignal.value
        ) {
          window.location.href = `/${params.playerId}/${data?.player_sessions_by_pk?.currentStep}`;
        }
      });
  });

  useSignalEffect(() => {
    if (
      currentStepNameSignal.value &&
      currentStepNameSignal.value !== params.currentStep
    ) {
      urqlGql
        .updatePlayerProgress({
          newStep: currentStepNameSignal.value,
          playerId: params.playerId,
        })
        .toPromise()
        .then(() => {
          navigate(`/${params.playerId}/${currentStepNameSignal.value}`, {
            unstable_viewTransition: true,
          });
        });
    }
  });

  return (
    <>
      <Onboarder<OnboarderState, ExtraStepProps>
        steps={steps}
        initialStep={params.currentStep}
        finalSteps={finalSteps}
        structure={structure}
        StepContainer={FancyStepContainer}
        debug={debug}
      />
    </>
  );
};

export default Fancy;
