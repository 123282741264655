import { useEffect, useState, useRef } from "react";
import { Fancy } from "./screens";
import { LoaderParams } from "@the-immersive/types";
import {
  createBrowserRouter,
  RouterProvider,
  useLoaderData,
  useNavigate,
  Outlet,
  Params,
  useParams,
} from "react-router-dom";
import * as Sentry from "@sentry/react";
import { Runes } from "./screens/runes/runes";
import { Stars } from "./screens/stars/stars";
import { Postcard } from "./screens/postcard/postcard";
import "./App.scss";
import "./logo-loader.scss";
import "./page.scss";
import "./screens/login/login.scss";

// import "../../src/components/text-input/text-input.scss";

// import "../../src/components/mainframe/mainframe.scss";

import { isEyeballVisibleSignal } from "@the-immersive/signals";
import { urqlGql } from "@the-immersive/gql";

import {
  VolumeControl,
  Eyeball,
  bgMusic,
  soundEffects,
} from "@the-immersive/components";

export const InitialScreen = () => {
  const loaderData = useLoaderData() as LoaderParams;
  const navigate = useNavigate();

  useEffect(() => {
    navigate(`/${loaderData.playerId}/${loaderData.currentStep}`, {
      unstable_viewTransition: true,
    });
  }, [loaderData.playerId, loaderData.currentStep, navigate]);

  return <Outlet />;
};

function transforms(x: number, y: number, el: HTMLElement) {
  const originalTransformValues =
    "translate3d(-50%, -50%, 0) perspective(300px) scale(0.8) translateY(-65px)";

  const constrain = 100;
  const box = el.getBoundingClientRect();
  const calcX = -(y - box.y - box.height / 2) / constrain;
  const calcY = (x - box.x - box.width / 2) / constrain;

  return `${originalTransformValues} rotateX(${calcX}deg) rotateY(${calcY}deg)`;
}

export const Root = () => {
  const [hasLoaded, setHasLoaded] = useState(false);
  const imgRefContainer = useRef<HTMLButtonElement>(null);
  const mouseMoveContainer = useRef<HTMLDivElement>(null);

  const params = useParams() as LoaderParams;
  const [lazyLoadedParams, setLazyParams] = useState<LoaderParams>();

  const navigate = useNavigate();

  // useEffect(() => {
  //   if (!hasLoaded) return;
  //   if (isEyeballVisibleSignal.value === true) {
  //     // isEyeballVisibleSignal.value = false;
  //   }
  // }, [hasLoaded]);

  useEffect(() => {
    if (params.currentStep && params.playerId) {
      // setHasLoaded(true);

      navigate(`/${params.playerId}/${params.currentStep}`, {
        unstable_viewTransition: true,
      });
    } else {
      // eyeballBgMusic.play();
      const p: Params<string> = {
        playerId: "",
      };
      routeDataLoader({ params: p }).then((result) => {
        if ("playerId" in result) {
          setLazyParams(result);
        }
      });
    }
  }, [params.currentStep, params.playerId, navigate]);

  function transformElement(x: number, y: number, el: HTMLElement | null) {
    if (el !== null) {
      el.style.transform = transforms(x, y, el);
    }
  }

  const onMouseMoveHandler = (e: MouseEvent) => {
    transformElement(e.clientX, e.clientY, imgRefContainer.current);
  };

  const onMouseOverHandler = () => {
    const currentValue = soundEffects.loader.hover.playing()
      ? soundEffects.loader.hover.volume()
      : 0;
    soundEffects.loader.hover.fade(currentValue, 0.6, 500);
  };

  const onMouseOutHandler = () => {
    const currentValue = soundEffects.loader.hover.playing()
      ? soundEffects.loader.hover.volume()
      : 0;

    soundEffects.loader.hover.fade(currentValue, 0, 1000);
  };

  const onClickHandler = (e: MouseEvent) => {
    const containerWithEvent = mouseMoveContainer.current;
    const buttonElement = imgRefContainer.current;

    // isEyeballVisibleSignal.value = false;

    e.preventDefault();
    e.stopPropagation();

    isEyeballVisibleSignal.value = false;

    if (containerWithEvent) {
      containerWithEvent.removeEventListener("mousemove", onMouseMoveHandler);
    }

    if (buttonElement) {
      buttonElement.removeEventListener("click", onClickHandler);
      buttonElement.removeEventListener("mouseover", onMouseOverHandler);
      buttonElement.removeEventListener("mouseout", onMouseOutHandler);
    }

    soundEffects.loader.click.fade(0.6, 0, 1000);
    soundEffects.loader.click.play();

    soundEffects.loader.hover.fade(0.4, 0, 200);
    bgMusic.loader.fade(0.3, 0.1, 200);

    setHasLoaded(true);

    // setHasLoaded(true);

    if (params.currentStep && params.playerId) {
      // setHasLoaded(true);

      navigate(`/${params.playerId}/${params.currentStep}`, {
        unstable_viewTransition: true,
      });
    } else if (lazyLoadedParams?.playerId) {
      navigate(
        `/${lazyLoadedParams.playerId}/${lazyLoadedParams.currentStep}`,
        {
          unstable_viewTransition: true,
        }
      );
    }
  };

  useEffect(() => {
    const containerWithEvent = mouseMoveContainer.current;
    const buttonElement = imgRefContainer.current;

    if (!containerWithEvent) return;
    if (!buttonElement) return;

    if (hasLoaded) return;

    containerWithEvent.addEventListener("mousemove", onMouseMoveHandler);
    buttonElement.addEventListener("mouseover", onMouseOverHandler);
    buttonElement.addEventListener("mouseout", onMouseOutHandler);
    buttonElement.addEventListener("click", onClickHandler);

    bgMusic.loader.play();

    // else {
    //   isEyeballVisibleSignal.value = false;
    //   containerWithEvent.removeEventListener("mousemove", onMouseMoveHandler);
    //   buttonElement.removeEventListener("mouseover", onMouseOverHandler);
    //   buttonElement.removeEventListener("mouseout", onMouseOutHandler);
    //   buttonElement.removeEventListener("click", onClickHandler);
    // }

    return () => {
      containerWithEvent.removeEventListener("mousemove", onMouseMoveHandler);
      buttonElement.removeEventListener("mouseover", onMouseOverHandler);
      buttonElement.removeEventListener("mouseout", onMouseOutHandler);
      buttonElement.removeEventListener("click", onClickHandler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  });

  // useSignalEffect(() => {});

  return (
    <div
      className={`c-loading-check ${hasLoaded ? "is-loaded" : "is-loading"}`}
      ref={mouseMoveContainer}
    >
      {/* <Tooltip open={isTooltipOpen}> */}
      <button
        className="c-logo-loader"
        ref={imgRefContainer}
        // onMouseOver={() => {
        //   hasTooltipAutoOpened.current = false;
        //   setTooltipOpen(false);
        // }}
      >
        <Eyeball stop={hasLoaded} />
      </button>

      {/* tooltipRoot={imgRefContainer.current as HTMLElement} */}
      {/* <TooltipContent>Click me</TooltipContent> */}
      {/* </Tooltip> */}
      <Outlet />
      <VolumeControl />
    </div>
  );
};

const createNewSession = async (): Promise<LoaderParams | Error> => {
  const { data, error } = await urqlGql.createSession();

  if (error) {
    throw Error(error.message);
  }

  if (data?.insert_player_sessions?.returning[0].id) {
    return {
      playerId: data?.insert_player_sessions?.returning[0].id,
      currentStep: data?.insert_player_sessions?.returning[0].currentStep,
      // data: data?.insert_vip_sessions?.returning[0].data,
    };
  } else {
    throw Error("unable to create a new session, please try again later");
  }
};

const getExistingSession = async (
  playerId: string
): Promise<LoaderParams | Error> => {
  const { data } = await urqlGql.getSession({
    sessionId: playerId || "",
  });

  if (data?.player_sessions[0].id) {
    return {
      playerId: data?.player_sessions[0].id,
      currentStep: data?.player_sessions[0].currentStep,
      // data: data?.vip_sessions[0].data,
    };
  } else {
    throw Error("no session found");
  }
};

const routeDataLoader = async ({
  params,
}: {
  params?: Params<string> | null;
}): Promise<LoaderParams | Error> => {
  if (
    !params?.playerId ||
    params?.playerId === undefined ||
    params?.playerId === ""
  ) {
    return await createNewSession();
  } else {
    try {
      const sessionFound = await getExistingSession(params.playerId);
      return sessionFound;
    } catch (error) {
      return await createNewSession();
    }
  }
};

const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouter(createBrowserRouter);

const router = sentryCreateBrowserRouter([
  {
    path: "/",
    element: <Root />,
    loader: routeDataLoader,
    children: [
      {
        path: "/:playerId",
        element: <InitialScreen />,
        loader: routeDataLoader,
      },
      {
        path: "/:playerId/:currentStep",
        element: <Fancy />,
        loader: routeDataLoader,
      },
    ],
  },
  {
    path: "/runes",
    element: <Runes />,
  },
  {
    path: "/stars",
    element: <Stars />,
  },
  {
    path: "/postcard",
    element: <Postcard />,
  },
]);

function App() {
  return <RouterProvider router={router} />;
}

export default App;
