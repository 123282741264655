import { MouseEventHandler } from "react";
import "./icon-button.scss";

export type IconButtonProps = {
  onClick?: MouseEventHandler<HTMLButtonElement>;
  children?: React.ReactNode;
  style?: React.CSSProperties;
  tabIndex?: number;
};

export const IconButton = ({
  onClick,
  children,
  style,
  tabIndex,
}: IconButtonProps) => {
  return (
    <button
      className="c-icon-button"
      onClick={onClick}
      style={style}
      tabIndex={tabIndex}
    >
      {children}
    </button>
  );
};
