import { Client, OperationContext } from '@urql/core';
import gql from 'graphql-tag';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  timestamptz: { input: any; output: any; }
};

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type Int_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Int']['input']>;
  _gt?: InputMaybe<Scalars['Int']['input']>;
  _gte?: InputMaybe<Scalars['Int']['input']>;
  _in?: InputMaybe<Array<Scalars['Int']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['Int']['input']>;
  _lte?: InputMaybe<Scalars['Int']['input']>;
  _neq?: InputMaybe<Scalars['Int']['input']>;
  _nin?: InputMaybe<Array<Scalars['Int']['input']>>;
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type String_Array_Comparison_Exp = {
  /** is the array contained in the given array value */
  _contained_in?: InputMaybe<Array<Scalars['String']['input']>>;
  /** does the array contain the given value */
  _contains?: InputMaybe<Array<Scalars['String']['input']>>;
  _eq?: InputMaybe<Array<Scalars['String']['input']>>;
  _gt?: InputMaybe<Array<Scalars['String']['input']>>;
  _gte?: InputMaybe<Array<Scalars['String']['input']>>;
  _in?: InputMaybe<Array<Array<Scalars['String']['input']>>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Array<Scalars['String']['input']>>;
  _lte?: InputMaybe<Array<Scalars['String']['input']>>;
  _neq?: InputMaybe<Array<Scalars['String']['input']>>;
  _nin?: InputMaybe<Array<Array<Scalars['String']['input']>>>;
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type String_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['String']['input']>;
  _gt?: InputMaybe<Scalars['String']['input']>;
  _gte?: InputMaybe<Scalars['String']['input']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: InputMaybe<Scalars['String']['input']>;
  _in?: InputMaybe<Array<Scalars['String']['input']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: InputMaybe<Scalars['String']['input']>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  /** does the column match the given pattern */
  _like?: InputMaybe<Scalars['String']['input']>;
  _lt?: InputMaybe<Scalars['String']['input']>;
  _lte?: InputMaybe<Scalars['String']['input']>;
  _neq?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: InputMaybe<Scalars['String']['input']>;
  _nin?: InputMaybe<Array<Scalars['String']['input']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given pattern */
  _nlike?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: InputMaybe<Scalars['String']['input']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: InputMaybe<Scalars['String']['input']>;
  /** does the column match the given SQL regular expression */
  _similar?: InputMaybe<Scalars['String']['input']>;
};

/** ordering argument of a cursor */
export enum Cursor_Ordering {
  /** ascending ordering of the cursor */
  Asc = 'ASC',
  /** descending ordering of the cursor */
  Desc = 'DESC'
}

/** mutation root */
export type Mutation_Root = {
  __typename?: 'mutation_root';
  /** delete data from the table: "player_sessions" */
  delete_player_sessions?: Maybe<Player_Sessions_Mutation_Response>;
  /** delete single row from the table: "player_sessions" */
  delete_player_sessions_by_pk?: Maybe<Player_Sessions>;
  /** delete data from the table: "puzzle_steps" */
  delete_puzzle_steps?: Maybe<Puzzle_Steps_Mutation_Response>;
  /** delete single row from the table: "puzzle_steps" */
  delete_puzzle_steps_by_pk?: Maybe<Puzzle_Steps>;
  /** insert data into the table: "player_sessions" */
  insert_player_sessions?: Maybe<Player_Sessions_Mutation_Response>;
  /** insert a single row into the table: "player_sessions" */
  insert_player_sessions_one?: Maybe<Player_Sessions>;
  /** insert data into the table: "puzzle_steps" */
  insert_puzzle_steps?: Maybe<Puzzle_Steps_Mutation_Response>;
  /** insert a single row into the table: "puzzle_steps" */
  insert_puzzle_steps_one?: Maybe<Puzzle_Steps>;
  /** update data of the table: "player_sessions" */
  update_player_sessions?: Maybe<Player_Sessions_Mutation_Response>;
  /** update single row of the table: "player_sessions" */
  update_player_sessions_by_pk?: Maybe<Player_Sessions>;
  /** update multiples rows of table: "player_sessions" */
  update_player_sessions_many?: Maybe<Array<Maybe<Player_Sessions_Mutation_Response>>>;
  /** update data of the table: "puzzle_steps" */
  update_puzzle_steps?: Maybe<Puzzle_Steps_Mutation_Response>;
  /** update single row of the table: "puzzle_steps" */
  update_puzzle_steps_by_pk?: Maybe<Puzzle_Steps>;
  /** update multiples rows of table: "puzzle_steps" */
  update_puzzle_steps_many?: Maybe<Array<Maybe<Puzzle_Steps_Mutation_Response>>>;
};


/** mutation root */
export type Mutation_RootDelete_Player_SessionsArgs = {
  where: Player_Sessions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Player_Sessions_By_PkArgs = {
  session_id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Puzzle_StepsArgs = {
  where: Puzzle_Steps_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Puzzle_Steps_By_PkArgs = {
  name: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootInsert_Player_SessionsArgs = {
  objects: Array<Player_Sessions_Insert_Input>;
  on_conflict?: InputMaybe<Player_Sessions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Player_Sessions_OneArgs = {
  object: Player_Sessions_Insert_Input;
  on_conflict?: InputMaybe<Player_Sessions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Puzzle_StepsArgs = {
  objects: Array<Puzzle_Steps_Insert_Input>;
  on_conflict?: InputMaybe<Puzzle_Steps_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Puzzle_Steps_OneArgs = {
  object: Puzzle_Steps_Insert_Input;
  on_conflict?: InputMaybe<Puzzle_Steps_On_Conflict>;
};


/** mutation root */
export type Mutation_RootUpdate_Player_SessionsArgs = {
  _set?: InputMaybe<Player_Sessions_Set_Input>;
  where: Player_Sessions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Player_Sessions_By_PkArgs = {
  _set?: InputMaybe<Player_Sessions_Set_Input>;
  pk_columns: Player_Sessions_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Player_Sessions_ManyArgs = {
  updates: Array<Player_Sessions_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Puzzle_StepsArgs = {
  _set?: InputMaybe<Puzzle_Steps_Set_Input>;
  where: Puzzle_Steps_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Puzzle_Steps_By_PkArgs = {
  _set?: InputMaybe<Puzzle_Steps_Set_Input>;
  pk_columns: Puzzle_Steps_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Puzzle_Steps_ManyArgs = {
  updates: Array<Puzzle_Steps_Updates>;
};

/** column ordering options */
export enum Order_By {
  /** in ascending order, nulls last */
  Asc = 'asc',
  /** in ascending order, nulls first */
  AscNullsFirst = 'asc_nulls_first',
  /** in ascending order, nulls last */
  AscNullsLast = 'asc_nulls_last',
  /** in descending order, nulls first */
  Desc = 'desc',
  /** in descending order, nulls first */
  DescNullsFirst = 'desc_nulls_first',
  /** in descending order, nulls last */
  DescNullsLast = 'desc_nulls_last'
}

/** columns and relationships of "player_sessions" */
export type Player_Sessions = {
  __typename?: 'player_sessions';
  created_at: Scalars['timestamptz']['output'];
  currentStep: Puzzle_Steps_Enum;
  messages?: Maybe<Array<Scalars['String']['output']>>;
  /** An object relationship */
  puzzle_step: Puzzle_Steps;
  session_id: Scalars['String']['output'];
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** aggregated selection of "player_sessions" */
export type Player_Sessions_Aggregate = {
  __typename?: 'player_sessions_aggregate';
  aggregate?: Maybe<Player_Sessions_Aggregate_Fields>;
  nodes: Array<Player_Sessions>;
};

export type Player_Sessions_Aggregate_Bool_Exp = {
  count?: InputMaybe<Player_Sessions_Aggregate_Bool_Exp_Count>;
};

export type Player_Sessions_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Player_Sessions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Player_Sessions_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "player_sessions" */
export type Player_Sessions_Aggregate_Fields = {
  __typename?: 'player_sessions_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Player_Sessions_Max_Fields>;
  min?: Maybe<Player_Sessions_Min_Fields>;
};


/** aggregate fields of "player_sessions" */
export type Player_Sessions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Player_Sessions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "player_sessions" */
export type Player_Sessions_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Player_Sessions_Max_Order_By>;
  min?: InputMaybe<Player_Sessions_Min_Order_By>;
};

/** input type for inserting array relation for remote table "player_sessions" */
export type Player_Sessions_Arr_Rel_Insert_Input = {
  data: Array<Player_Sessions_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Player_Sessions_On_Conflict>;
};

/** Boolean expression to filter rows from the table "player_sessions". All fields are combined with a logical 'AND'. */
export type Player_Sessions_Bool_Exp = {
  _and?: InputMaybe<Array<Player_Sessions_Bool_Exp>>;
  _not?: InputMaybe<Player_Sessions_Bool_Exp>;
  _or?: InputMaybe<Array<Player_Sessions_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  currentStep?: InputMaybe<Puzzle_Steps_Enum_Comparison_Exp>;
  messages?: InputMaybe<String_Array_Comparison_Exp>;
  puzzle_step?: InputMaybe<Puzzle_Steps_Bool_Exp>;
  session_id?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "player_sessions" */
export enum Player_Sessions_Constraint {
  /** unique or primary key constraint on columns "session_id" */
  PlayerSessionsPkey = 'player_sessions_pkey'
}

/** input type for inserting data into table "player_sessions" */
export type Player_Sessions_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  currentStep?: InputMaybe<Puzzle_Steps_Enum>;
  messages?: InputMaybe<Array<Scalars['String']['input']>>;
  puzzle_step?: InputMaybe<Puzzle_Steps_Obj_Rel_Insert_Input>;
  session_id?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type Player_Sessions_Max_Fields = {
  __typename?: 'player_sessions_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  messages?: Maybe<Array<Scalars['String']['output']>>;
  session_id?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "player_sessions" */
export type Player_Sessions_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  messages?: InputMaybe<Order_By>;
  session_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Player_Sessions_Min_Fields = {
  __typename?: 'player_sessions_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  messages?: Maybe<Array<Scalars['String']['output']>>;
  session_id?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "player_sessions" */
export type Player_Sessions_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  messages?: InputMaybe<Order_By>;
  session_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "player_sessions" */
export type Player_Sessions_Mutation_Response = {
  __typename?: 'player_sessions_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Player_Sessions>;
};

/** on_conflict condition type for table "player_sessions" */
export type Player_Sessions_On_Conflict = {
  constraint: Player_Sessions_Constraint;
  update_columns?: Array<Player_Sessions_Update_Column>;
  where?: InputMaybe<Player_Sessions_Bool_Exp>;
};

/** Ordering options when selecting data from "player_sessions". */
export type Player_Sessions_Order_By = {
  created_at?: InputMaybe<Order_By>;
  currentStep?: InputMaybe<Order_By>;
  messages?: InputMaybe<Order_By>;
  puzzle_step?: InputMaybe<Puzzle_Steps_Order_By>;
  session_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: player_sessions */
export type Player_Sessions_Pk_Columns_Input = {
  session_id: Scalars['String']['input'];
};

/** select columns of table "player_sessions" */
export enum Player_Sessions_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CurrentStep = 'currentStep',
  /** column name */
  Messages = 'messages',
  /** column name */
  SessionId = 'session_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "player_sessions" */
export type Player_Sessions_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  currentStep?: InputMaybe<Puzzle_Steps_Enum>;
  messages?: InputMaybe<Array<Scalars['String']['input']>>;
  session_id?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** Streaming cursor of the table "player_sessions" */
export type Player_Sessions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Player_Sessions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Player_Sessions_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  currentStep?: InputMaybe<Puzzle_Steps_Enum>;
  messages?: InputMaybe<Array<Scalars['String']['input']>>;
  session_id?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** update columns of table "player_sessions" */
export enum Player_Sessions_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CurrentStep = 'currentStep',
  /** column name */
  Messages = 'messages',
  /** column name */
  SessionId = 'session_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Player_Sessions_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Player_Sessions_Set_Input>;
  /** filter the rows which have to be updated */
  where: Player_Sessions_Bool_Exp;
};

/** columns and relationships of "puzzle_steps" */
export type Puzzle_Steps = {
  __typename?: 'puzzle_steps';
  name: Scalars['String']['output'];
  /** An array relationship */
  player_sessions: Array<Player_Sessions>;
  /** An aggregate relationship */
  player_sessions_aggregate: Player_Sessions_Aggregate;
};


/** columns and relationships of "puzzle_steps" */
export type Puzzle_StepsPlayer_SessionsArgs = {
  distinct_on?: InputMaybe<Array<Player_Sessions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Player_Sessions_Order_By>>;
  where?: InputMaybe<Player_Sessions_Bool_Exp>;
};


/** columns and relationships of "puzzle_steps" */
export type Puzzle_StepsPlayer_Sessions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Player_Sessions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Player_Sessions_Order_By>>;
  where?: InputMaybe<Player_Sessions_Bool_Exp>;
};

/** aggregated selection of "puzzle_steps" */
export type Puzzle_Steps_Aggregate = {
  __typename?: 'puzzle_steps_aggregate';
  aggregate?: Maybe<Puzzle_Steps_Aggregate_Fields>;
  nodes: Array<Puzzle_Steps>;
};

/** aggregate fields of "puzzle_steps" */
export type Puzzle_Steps_Aggregate_Fields = {
  __typename?: 'puzzle_steps_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Puzzle_Steps_Max_Fields>;
  min?: Maybe<Puzzle_Steps_Min_Fields>;
};


/** aggregate fields of "puzzle_steps" */
export type Puzzle_Steps_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Puzzle_Steps_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "puzzle_steps". All fields are combined with a logical 'AND'. */
export type Puzzle_Steps_Bool_Exp = {
  _and?: InputMaybe<Array<Puzzle_Steps_Bool_Exp>>;
  _not?: InputMaybe<Puzzle_Steps_Bool_Exp>;
  _or?: InputMaybe<Array<Puzzle_Steps_Bool_Exp>>;
  name?: InputMaybe<String_Comparison_Exp>;
  player_sessions?: InputMaybe<Player_Sessions_Bool_Exp>;
  player_sessions_aggregate?: InputMaybe<Player_Sessions_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "puzzle_steps" */
export enum Puzzle_Steps_Constraint {
  /** unique or primary key constraint on columns "name" */
  PuzzleStepsPkey = 'puzzle_steps_pkey'
}

export enum Puzzle_Steps_Enum {
  FinalStep = 'finalStep',
  MissingItem = 'missingItem',
  Step1 = 'step1',
  Step2 = 'step2',
  Step3 = 'step3',
  Step4 = 'step4',
  Step5 = 'step5',
  Step6 = 'step6',
  Step7 = 'step7',
  Step8 = 'step8',
  Step9 = 'step9',
  Step10 = 'step10',
  Step11 = 'step11',
  Step12 = 'step12',
  Step13 = 'step13'
}

/** Boolean expression to compare columns of type "puzzle_steps_enum". All fields are combined with logical 'AND'. */
export type Puzzle_Steps_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Puzzle_Steps_Enum>;
  _in?: InputMaybe<Array<Puzzle_Steps_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Puzzle_Steps_Enum>;
  _nin?: InputMaybe<Array<Puzzle_Steps_Enum>>;
};

/** input type for inserting data into table "puzzle_steps" */
export type Puzzle_Steps_Insert_Input = {
  name?: InputMaybe<Scalars['String']['input']>;
  player_sessions?: InputMaybe<Player_Sessions_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Puzzle_Steps_Max_Fields = {
  __typename?: 'puzzle_steps_max_fields';
  name?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Puzzle_Steps_Min_Fields = {
  __typename?: 'puzzle_steps_min_fields';
  name?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "puzzle_steps" */
export type Puzzle_Steps_Mutation_Response = {
  __typename?: 'puzzle_steps_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Puzzle_Steps>;
};

/** input type for inserting object relation for remote table "puzzle_steps" */
export type Puzzle_Steps_Obj_Rel_Insert_Input = {
  data: Puzzle_Steps_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Puzzle_Steps_On_Conflict>;
};

/** on_conflict condition type for table "puzzle_steps" */
export type Puzzle_Steps_On_Conflict = {
  constraint: Puzzle_Steps_Constraint;
  update_columns?: Array<Puzzle_Steps_Update_Column>;
  where?: InputMaybe<Puzzle_Steps_Bool_Exp>;
};

/** Ordering options when selecting data from "puzzle_steps". */
export type Puzzle_Steps_Order_By = {
  name?: InputMaybe<Order_By>;
  player_sessions_aggregate?: InputMaybe<Player_Sessions_Aggregate_Order_By>;
};

/** primary key columns input for table: puzzle_steps */
export type Puzzle_Steps_Pk_Columns_Input = {
  name: Scalars['String']['input'];
};

/** select columns of table "puzzle_steps" */
export enum Puzzle_Steps_Select_Column {
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "puzzle_steps" */
export type Puzzle_Steps_Set_Input = {
  name?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "puzzle_steps" */
export type Puzzle_Steps_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Puzzle_Steps_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Puzzle_Steps_Stream_Cursor_Value_Input = {
  name?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "puzzle_steps" */
export enum Puzzle_Steps_Update_Column {
  /** column name */
  Name = 'name'
}

export type Puzzle_Steps_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Puzzle_Steps_Set_Input>;
  /** filter the rows which have to be updated */
  where: Puzzle_Steps_Bool_Exp;
};

export type Query_Root = {
  __typename?: 'query_root';
  /** An array relationship */
  player_sessions: Array<Player_Sessions>;
  /** An aggregate relationship */
  player_sessions_aggregate: Player_Sessions_Aggregate;
  /** fetch data from the table: "player_sessions" using primary key columns */
  player_sessions_by_pk?: Maybe<Player_Sessions>;
  /** fetch data from the table: "puzzle_steps" */
  puzzle_steps: Array<Puzzle_Steps>;
  /** fetch aggregated fields from the table: "puzzle_steps" */
  puzzle_steps_aggregate: Puzzle_Steps_Aggregate;
  /** fetch data from the table: "puzzle_steps" using primary key columns */
  puzzle_steps_by_pk?: Maybe<Puzzle_Steps>;
};


export type Query_RootPlayer_SessionsArgs = {
  distinct_on?: InputMaybe<Array<Player_Sessions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Player_Sessions_Order_By>>;
  where?: InputMaybe<Player_Sessions_Bool_Exp>;
};


export type Query_RootPlayer_Sessions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Player_Sessions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Player_Sessions_Order_By>>;
  where?: InputMaybe<Player_Sessions_Bool_Exp>;
};


export type Query_RootPlayer_Sessions_By_PkArgs = {
  session_id: Scalars['String']['input'];
};


export type Query_RootPuzzle_StepsArgs = {
  distinct_on?: InputMaybe<Array<Puzzle_Steps_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Puzzle_Steps_Order_By>>;
  where?: InputMaybe<Puzzle_Steps_Bool_Exp>;
};


export type Query_RootPuzzle_Steps_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Puzzle_Steps_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Puzzle_Steps_Order_By>>;
  where?: InputMaybe<Puzzle_Steps_Bool_Exp>;
};


export type Query_RootPuzzle_Steps_By_PkArgs = {
  name: Scalars['String']['input'];
};

export type Subscription_Root = {
  __typename?: 'subscription_root';
  /** An array relationship */
  player_sessions: Array<Player_Sessions>;
  /** An aggregate relationship */
  player_sessions_aggregate: Player_Sessions_Aggregate;
  /** fetch data from the table: "player_sessions" using primary key columns */
  player_sessions_by_pk?: Maybe<Player_Sessions>;
  /** fetch data from the table in a streaming manner: "player_sessions" */
  player_sessions_stream: Array<Player_Sessions>;
  /** fetch data from the table: "puzzle_steps" */
  puzzle_steps: Array<Puzzle_Steps>;
  /** fetch aggregated fields from the table: "puzzle_steps" */
  puzzle_steps_aggregate: Puzzle_Steps_Aggregate;
  /** fetch data from the table: "puzzle_steps" using primary key columns */
  puzzle_steps_by_pk?: Maybe<Puzzle_Steps>;
  /** fetch data from the table in a streaming manner: "puzzle_steps" */
  puzzle_steps_stream: Array<Puzzle_Steps>;
};


export type Subscription_RootPlayer_SessionsArgs = {
  distinct_on?: InputMaybe<Array<Player_Sessions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Player_Sessions_Order_By>>;
  where?: InputMaybe<Player_Sessions_Bool_Exp>;
};


export type Subscription_RootPlayer_Sessions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Player_Sessions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Player_Sessions_Order_By>>;
  where?: InputMaybe<Player_Sessions_Bool_Exp>;
};


export type Subscription_RootPlayer_Sessions_By_PkArgs = {
  session_id: Scalars['String']['input'];
};


export type Subscription_RootPlayer_Sessions_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Player_Sessions_Stream_Cursor_Input>>;
  where?: InputMaybe<Player_Sessions_Bool_Exp>;
};


export type Subscription_RootPuzzle_StepsArgs = {
  distinct_on?: InputMaybe<Array<Puzzle_Steps_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Puzzle_Steps_Order_By>>;
  where?: InputMaybe<Puzzle_Steps_Bool_Exp>;
};


export type Subscription_RootPuzzle_Steps_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Puzzle_Steps_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Puzzle_Steps_Order_By>>;
  where?: InputMaybe<Puzzle_Steps_Bool_Exp>;
};


export type Subscription_RootPuzzle_Steps_By_PkArgs = {
  name: Scalars['String']['input'];
};


export type Subscription_RootPuzzle_Steps_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Puzzle_Steps_Stream_Cursor_Input>>;
  where?: InputMaybe<Puzzle_Steps_Bool_Exp>;
};

/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
export type Timestamptz_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['timestamptz']['input']>;
  _gt?: InputMaybe<Scalars['timestamptz']['input']>;
  _gte?: InputMaybe<Scalars['timestamptz']['input']>;
  _in?: InputMaybe<Array<Scalars['timestamptz']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['timestamptz']['input']>;
  _lte?: InputMaybe<Scalars['timestamptz']['input']>;
  _neq?: InputMaybe<Scalars['timestamptz']['input']>;
  _nin?: InputMaybe<Array<Scalars['timestamptz']['input']>>;
};

export type GetSessionQueryVariables = Exact<{
  sessionId?: InputMaybe<Scalars['String']['input']>;
}>;


export type GetSessionQuery = { __typename?: 'query_root', player_sessions: Array<{ __typename: 'player_sessions', currentStep: Puzzle_Steps_Enum, id: string }> };

export type CreateSessionMutationVariables = Exact<{ [key: string]: never; }>;


export type CreateSessionMutation = { __typename?: 'mutation_root', insert_player_sessions?: { __typename?: 'player_sessions_mutation_response', returning: Array<{ __typename: 'player_sessions', currentStep: Puzzle_Steps_Enum, id: string }> } | null };

export type PlayerProgressSubscriptionSubscriptionVariables = Exact<{
  playerId: Scalars['String']['input'];
}>;


export type PlayerProgressSubscriptionSubscription = { __typename?: 'subscription_root', player_sessions_by_pk?: { __typename: 'player_sessions', currentStep: Puzzle_Steps_Enum, id: string } | null };

export type UpdatePlayerProgressMutationVariables = Exact<{
  playerId: Scalars['String']['input'];
  newStep: Puzzle_Steps_Enum;
}>;


export type UpdatePlayerProgressMutation = { __typename?: 'mutation_root', update_player_sessions_by_pk?: { __typename: 'player_sessions', currentStep: Puzzle_Steps_Enum, id: string } | null };


export const GetSessionDocument = gql`
    query GetSession($sessionId: String) {
  player_sessions(limit: 1, where: {session_id: {_eq: $sessionId}}) {
    id: session_id
    currentStep
    __typename
  }
}
    `;
export const CreateSessionDocument = gql`
    mutation CreateSession {
  insert_player_sessions(objects: {}) {
    returning {
      id: session_id
      currentStep
      __typename
    }
  }
}
    `;
export const PlayerProgressSubscriptionDocument = gql`
    subscription PlayerProgressSubscription($playerId: String!) {
  player_sessions_by_pk(session_id: $playerId) {
    currentStep
    __typename
    id: session_id
  }
}
    `;
export const UpdatePlayerProgressDocument = gql`
    mutation UpdatePlayerProgress($playerId: String!, $newStep: puzzle_steps_enum!) {
  update_player_sessions_by_pk(
    _set: {currentStep: $newStep}
    pk_columns: {session_id: $playerId}
  ) {
    currentStep
    __typename
    id: session_id
  }
}
    `;
export const getSdk = (client: Client) => ({
      getSession(variables: GetSessionQueryVariables = {}, context?: Partial<OperationContext>) {
          return client.query<GetSessionQuery, GetSessionQueryVariables>(GetSessionDocument, variables, context);
      },
createSession(variables: CreateSessionMutationVariables = {}, context?: Partial<OperationContext>) {
          return client.mutation<CreateSessionMutation, CreateSessionMutationVariables>(CreateSessionDocument, variables, context);
      },
playerProgressSubscription(variables: PlayerProgressSubscriptionSubscriptionVariables, context?: Partial<OperationContext>) {
          return client.subscription<PlayerProgressSubscriptionSubscription, PlayerProgressSubscriptionSubscriptionVariables>(PlayerProgressSubscriptionDocument, variables, context);
      },
updatePlayerProgress(variables: UpdatePlayerProgressMutationVariables, context?: Partial<OperationContext>) {
          return client.mutation<UpdatePlayerProgressMutation, UpdatePlayerProgressMutationVariables>(UpdatePlayerProgressDocument, variables, context);
      }
    });
    export type SdkType = ReturnType<typeof getSdk>
