import { ReactNode } from "react";

import {
  // StepContainerComponentProps,
  Steps,
  // useGlobalState,
} from "@guestbell/onboarder";
import { default as TypeItCore } from "typeit";

import { Puzzle_Steps_Enum } from "@the-immersive/gql";

export type LoginFormInputs = {
  username: string;
  password: string;
};

export interface FancyProps {
  debug?: boolean;
}

export type StringOrNumber = string | number;

export type StandardQuestion = {
  continueButtonText?: string;
  answer: string | undefined;
  isDirty: boolean;
  errorMessage?: string;
  hints: string[];
  maxAttempts: number;
  numberOfHintsGiven: number;
  numberOfAttempts: number;
  correctAnswers: StringOrNumber[];
  hasCorrectAnswer: boolean | undefined;
  maxInputLength?: number;
};

export const StepNames = { ...Puzzle_Steps_Enum } as const;

export type OnboarderState = {
  [StepNames.Step1]: Omit<StandardQuestion, "answer" | "correctAnswers"> & {
    answer: {
      username: string;
      password: string;
    };
    correctAnswers: {
      username: string;
      password: string;
    };
  };
  [StepNames.Step2]: StandardQuestion;
  [StepNames.Step3]: StandardQuestion;
  [StepNames.Step4]: StandardQuestion;
  [StepNames.Step5]: StandardQuestion;
  [StepNames.Step6]: StandardQuestion;
  [StepNames.Step7]: StandardQuestion;
  [StepNames.Step8]: StandardQuestion;
  [StepNames.Step9]: StandardQuestion;
  [StepNames.Step10]: StandardQuestion;
  [StepNames.Step11]: StandardQuestion;
  [StepNames.Step12]: StandardQuestion;
  [StepNames.Step13]: StandardQuestion;
  [StepNames.MissingItem]: StandardQuestion;
  [StepNames.FinalStep]: never;
};

export type StepperState = Steps<OnboarderState, ExtraStepProps>;

export interface StepContainerProps {}

export type ExtraStepProps = {
  hideUi?: boolean;
  icon?: string;
  mainframeChildren?: React.ReactNode;
  prompt?: null | ((instance: TypeItCore) => TypeItCore);
  hints?: string[];
  title?: ReactNode;
  subtitle?: ReactNode;
  backgroundColor?: string;
  backgroundImage?: string;
  successMessage: string;
};

export type LoaderParams = {
  playerId: string;
  currentStep: Puzzle_Steps_Enum;
};

export { Puzzle_Steps_Enum };

export type { Steps };
