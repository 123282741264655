import { useState, useRef } from "react";
import endSafariVideo from "../../assets/videos/end-audio-hevc-safari.mp4";
import endChromeVideo from "../../assets/videos/end-audio-vp9-chrome.webm";

import {
  hasCorrectAnswerMessageBeenSeen,
  isEyeballVisibleSignal,
  machineExplodingState,
  useSignalEffect,
  videoTransitionState,
  globalVolumeNumber,
} from "@the-immersive/signals";

import portalEndingSafariVideo from "../../assets/videos/portal-ending-hevc-safari.mp4";
import portalEndingChromeVideo from "../../assets/videos/portal-ending-vp9-chrome.webm";

import transitionSafariVideo from "../../assets/videos/transition-hevc-safari.mp4";
import transitionChromeVideo from "../../assets/videos/transition-vp9-chrome.webm";

// import introHorizontalSafariVideo from '../../assets/videos/start-horizontal-hevc-safari.mp4';
// import introHorizontalChromeVideo from '../../assets/videos/start-horizontal-vp9-chrome.webm';

// import introVerticalSafariVideo '../../assets/videos/start-vertical-hevc-safari.mp4';
// import introVerticalChromeVideo from '../../assets/videos/start-vertical-vp9-chrome.webm';

export const MainframeInner = ({
  children,
  numberOfSteps = 12,
  activeStep = 1,
  imagePath,
}: {
  children?: React.ReactNode;
  numberOfSteps?: number;
  activeStep?: number;
  imagePath?: string;
}) => {
  const [, setIsDonePlayingOuterVideo] = useState(false);
  const [isDonePlayingInnerVideo, setIsDonePlayingInnerVideo] = useState(false);

  const innerVideoRef = useRef<HTMLVideoElement>(null);
  const outerVideoRef = useRef<HTMLVideoElement>(null);
  const innerVideoTransitionRef = useRef<HTMLVideoElement>(null);

  const activeStepRef = useRef<number>(activeStep);

  const [nextImagePath, setNextImagePath] = useState(imagePath);

  const currentImagePathRef = useRef<string | undefined>(imagePath);

  useSignalEffect(() => {
    if (hasCorrectAnswerMessageBeenSeen.value === true) {
      if (!imagePath) return;
      if (activeStep === numberOfSteps) return;
      if (activeStep === 0) return;

      if (
        videoTransitionState.value === "not-started" &&
        activeStep !== activeStepRef.current &&
        hasCorrectAnswerMessageBeenSeen.value === true
      ) {
        activeStepRef.current = activeStep;

        if (videoTransitionState.value === "not-started") {
          videoTransitionState.value = "playing";

          if (innerVideoTransitionRef.current) {
            innerVideoTransitionRef.current.play();
          }
        }
      }
    }

    globalVolumeNumber.subscribe((newValue) => {
      if (outerVideoRef.current) {
        outerVideoRef.current.volume = newValue;
      }

      if (innerVideoRef.current) {
        innerVideoRef.current.volume = newValue;
      }

      if (innerVideoTransitionRef.current) {
        innerVideoTransitionRef.current.volume = newValue;
      }
    });
  });

  return (
    <>
      <div
        className="c-mainframe"
        style={
          {
            "--rotation-offset": (numberOfSteps - 1) * -1,
          } as React.CSSProperties
        }
      >
        <div className="c-mainframe__frame">
          {activeStep === numberOfSteps && !isEyeballVisibleSignal.value && (
            <video
              width="640"
              height="640"
              ref={outerVideoRef}
              autoPlay={!isEyeballVisibleSignal.value}
              onPlay={() => {
                if (innerVideoRef.current) {
                  innerVideoRef.current.setAttribute("autoplay", "true");
                  innerVideoRef.current.play();
                }
              }}
              onEnded={() => {
                setIsDonePlayingOuterVideo(true);
              }}
              playsInline
              style={{
                zIndex: 1000,
                gridRow: "1/1",
                gridColumn: "1/1",
                objectFit: "fill",
                position: "absolute",
                top: "0",
                left: "0",
                bottom: "0",
                right: "0",
                maxHeight: "100%",
                maxWidth: "100%",
              }}
            >
              <source src={endSafariVideo} type='video/mp4; codecs="hvc1"' />
              <source src={endChromeVideo} type="video/webm" />
            </video>
          )}
          <div className="c-mainframe__levels">
            {[...Array(numberOfSteps)].map((_letter, index) => (
              <span
                key={`${_letter}-${index}`}
                style={
                  {
                    "--char-index": index + index,
                    "--actual-index": index,
                  } as React.CSSProperties
                }
                className={`c-mainframe__level ${
                  index + 1 === activeStep || index < activeStep
                    ? "c-mainframe__level--selected"
                    : ""
                }`}
              >
                <span className="c-mainframe__level-text">8</span>
              </span>
            ))}
          </div>
        </div>
        <div className="c-mainframe__glass">
          {imagePath ? (
            <>
              <video
                width="640"
                height="640"
                ref={innerVideoTransitionRef}
                className={`c-mainframe__inner-video c-mainframe__inner-video--transition ${
                  videoTransitionState.value === "playing"
                    ? "is-visible"
                    : "is-hidden"
                }`}
                onEnded={() => {
                  // setIsDonePlayingTransitionVideo(true);
                }}
                onPlay={() => {
                  videoTransitionState.value = "playing";
                }}
                onTimeUpdate={(e) => {
                  if (e.currentTarget.currentTime >= 3) {
                    if (
                      imagePath &&
                      currentImagePathRef.current !== imagePath
                    ) {
                      setNextImagePath(imagePath);
                      currentImagePathRef.current = imagePath;
                    }
                  }

                  if (e.currentTarget.currentTime >= 5) {
                    videoTransitionState.value = "done";
                  }
                }}
                autoPlay={
                  !isEyeballVisibleSignal.value &&
                  videoTransitionState.value === "not-started"
                }
                playsInline
                style={{
                  gridRow: "1/1",
                  gridColumn: "1/1",
                  objectFit: "contain",
                  top: "50%",
                  left: "50%",
                  maxHeight: "80%",
                  maxWidth: "80%",
                  position: "absolute",
                  zIndex: 20,
                  opacity: isDonePlayingInnerVideo ? 0 : 1,
                  transition: "all 1s ease",
                  transform: `translate3d(-50%, -50%, 0) scale(${
                    isDonePlayingInnerVideo ? 0 : 1
                  })`,
                  borderRadius: "50rem",
                  transformOrigin: "center center",
                }}
              >
                <source
                  src={transitionSafariVideo}
                  type='video/mp4; codecs="hvc1"'
                />
                <source src={transitionChromeVideo} type="video/webm" />
              </video>
              <img
                src={nextImagePath}
                className={`c-mainframe__asset ${
                  videoTransitionState.value === "done"
                    ? "is-visible"
                    : "is-hidden"
                }`}
              />
            </>
          ) : (
            <>
              {activeStep === numberOfSteps && (
                <video
                  width="640"
                  height="640"
                  ref={innerVideoRef}
                  className={`c-mainframe__inner-video c-mainframe__inner-video--finale ${
                    !isDonePlayingInnerVideo ? "is-visible" : "is-hidden"
                  }`}
                  onTimeUpdate={(e) => {
                    if (e.currentTarget.currentTime >= 27) {
                      machineExplodingState.value = "repaired";
                    }

                    if (e.currentTarget.currentTime >= 8) {
                      e.currentTarget.classList.add("is-disappearing");
                    }
                  }}
                  onEnded={() => {
                    setIsDonePlayingInnerVideo(true);
                  }}
                  autoPlay={!isEyeballVisibleSignal.value}
                  playsInline
                  style={{
                    gridRow: "1/1",
                    gridColumn: "1/1",
                    objectFit: "contain",
                    top: "50%",
                    left: "50%",
                    maxHeight: "80%",
                    maxWidth: "80%",
                    position: "absolute",
                    zIndex: 20,
                    opacity: isDonePlayingInnerVideo ? 0 : 1,
                    transition: "all 1s ease",
                    transform: `translate3d(-50%, -50%, 0) scale(${
                      isDonePlayingInnerVideo ? 0 : 1
                    })`,
                    borderRadius: "50rem",
                    transformOrigin: "center center",
                  }}
                >
                  <source
                    src={portalEndingSafariVideo}
                    type='video/mp4; codecs="hvc1"'
                  />
                  <source src={portalEndingChromeVideo} type="video/webm" />
                </video>
              )}

              {children}
            </>
          )}
        </div>
      </div>
    </>
  );
};
