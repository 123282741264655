/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useRef, useState } from "react";
import { Howler, VolumePopover } from "@the-immersive/components";
import "./volume-control.scss";
import { signal, useSignalEffect } from "@preact/signals-react";

import { globalVolumeNumber } from "@the-immersive/signals";

import mute from "@the-immersive/icons/mute.svg";
import lowest from "@the-immersive/icons/volume-lowest.svg";
import low from "@the-immersive/icons/volume-low.svg";
import medium from "@the-immersive/icons/volume-medium.svg";
import high from "@the-immersive/icons/volume-high.svg";
import highest from "@the-immersive/icons/volume-highest.svg";

const icons = {
  mute: mute,
  lowest: lowest,
  low: low,
  medium: medium,
  high: high,
  highest: highest,
};

const lerp = (x: number, y: number, a: number) => x * (1 - a) + y * a;
const invlerp = (x: number, y: number, a: number) => clamp((a - x) / (y - x));
const clamp = (a: number, min = 0, max = 1) => Math.min(max, Math.max(min, a));
const range = (x1: number, y1: number, x2: number, y2: number, a: number) =>
  lerp(x2, y2, invlerp(x1, y1, a));

const volumeLevels = {
  mute: "mute",
  lowest: "lowest",
  low: "low",
  medium: "medium",
  high: "high",
  highest: "highest",
} as const;
type VolumeLevel = keyof typeof volumeLevels;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const isVolume = (x: any): x is VolumeLevel =>
  Object.keys(volumeLevels).includes(x);

const localStorageVolumeName = window.localStorage.getItem(
  "the-immersive-global-volume-name"
);

const volumeLevelName = signal<VolumeLevel>(
  isVolume(localStorageVolumeName)
    ? localStorageVolumeName
    : volumeLevels.medium
);

const volumeLevelNumber = signal<number>(
  Object.keys(volumeLevels).indexOf(volumeLevelName.value) || 5
);

export const VolumeControl = () => {
  const inputRef = useRef<HTMLInputElement>(null);

  const [triggerIcon, setTriggerIcon] = useState<string>(
    icons[volumeLevelName.value] || icons["medium"]
  );

  const adjustGlobalValue = () => {
    const x = Object.keys(volumeLevels).indexOf(volumeLevelName.value);

    const valueToUse = range(0, Object.keys(volumeLevels).length - 1, 0, 1, x);

    Howler.volume(valueToUse);

    if (!inputRef.current) return;

    inputRef.current.value = x.toString();
  };

  useEffect(() => {
    document.addEventListener("visibilitychange", visibilityChangeHandler);
    adjustGlobalValue();

    return () => {
      document.removeEventListener("visibilitychange", visibilityChangeHandler);
    };
  });

  useSignalEffect(() => {
    adjustGlobalValue();
  });

  function visibilityChangeHandler() {
    if (document.hidden) {
      Howler.mute(true);
    } else {
      Howler.mute(false);
    }
  }

  return (
    <div className="c-volume-control">
      <VolumePopover triggerSVG={triggerIcon}>
        <input
          type="range"
          ref={inputRef}
          id="ratio"
          name="volume"
          min="0"
          max="5"
          step="1"
          defaultValue={Object.keys(volumeLevels).indexOf(
            volumeLevelName.value
          )}
          onInput={(e) => {
            const volumeName =
              Object.keys(volumeLevels)[parseInt(e.currentTarget.value)];

            const volumeNumber = Object.keys(volumeLevels).indexOf(volumeName);

            if (isVolume(volumeName)) {
              volumeLevelName.value = volumeName;
              volumeLevelNumber.value = volumeNumber;

              window.localStorage.setItem(
                "the-immersive-global-volume-name",
                volumeName
              );

              window.localStorage.setItem(
                "the-immersive-global-volume-number",
                volumeNumber.toString()
              );
            }

            if (volumeName in icons) {
              const iconName = icons[volumeName as keyof typeof icons];
              setTriggerIcon(iconName);

              const valueToUse = range(
                0,
                Object.keys(volumeLevels).length - 1,
                0,
                1,
                volumeNumber
              );

              globalVolumeNumber.value = valueToUse;
              Howler.volume(valueToUse);
            }
          }}
        />
      </VolumePopover>
    </div>
  );
};
