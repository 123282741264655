import { Howl, Howler } from "howler";

import eyeballBgMusicFile from "@the-immersive/music/eyeball.bg.mp3";
import finaleBgMusicFile from "@the-immersive/music/finale.bg.mp3";
import introBgMusicFile from "@the-immersive/music/login-bg.mp3";
// import mysteryBgMusicFile from "../../assets/audio/music/mystical.bg.mp3";

const loader = new Howl({
  src: [eyeballBgMusicFile],
  volume: 0.2,
  loop: true,
});

const login = new Howl({
  src: [introBgMusicFile],
  volume: 0.3,
  loop: true,
});

const finale = new Howl({
  src: [finaleBgMusicFile],
  volume: 0.3,
  loop: true,
});

// export const mysteryBgMusicEffect = new Howl({
//   src: [mysteryBgMusicFile],
//   volume: 0.3,
//   loop: true,
// });

export { Howler };

export const bgMusic = {
  loader,
  login,
  finale,
};
