import { Directions, noOp } from "./utils.ts";
import "./measure-display-view.css";
// import { OnDragParams } from "./drag-controller.ts";

// function template({
//   rulersOrientation,
// }: {
//   rulersOrientation: keyof typeof Directions;
// }) {
//   const orientationClassName = `${rulersOrientation}-rulers`;
//   return `
//     <div class="measure-display-view ${orientationClassName}">
//       <div class="credit">
//         <div class="chip"></div>
//       </div>
//       <div class="rulers">
//         <div class="left-ruler"></div>
//         <div class="right-ruler interactive-ruler">
//           <div class="drag-area"></div>
//         </div>
//         <div class="top-ruler"></div>
//         <div class="bottom-ruler interactive-ruler">
//           <div class="drag-area"></div>
//         </div>
//       </div>
//     </div>
//   `;
// }

const cssParameters = [
  "creditBackground",
  "chipBackground",
  "rulersWidth",
  "rulersLength",
  "staticRulersColor",
  "interactiveRulersColor",
  "hoveredInteractiveRulersColor",
  "activeInteractiveRulersColor",
  "hoveredInteractiveRulersShadow",
  "activeInteractiveRulersShadow",
  "dragAreaWidth",
  "creditWidth",
  "creditHeight",
  "creditRoundedCorners",
  "chipTop",
  "chipLeft",
  "chipWidth",
  "chipHeight",
  "chipRoundedCorners",
] as const;

export default function MeasureDisplayView({
  // node,
  mainElem,
  creditElem,
  // rulersOrientation = Directions.horizontal,
  ratio = 4,
  onChange = noOp,
}: // cssArgs,
{
  node: HTMLElement | HTMLDivElement;
  mainElem: HTMLElement;
  creditElem: HTMLElement;
  rulersOrientation?: keyof typeof Directions;
  ratio?: number;
  onChange?: (ratio: number) => void;
  cssArgs?: typeof cssParameters | null;
}) {
  // node.innerHTML = template({ rulersOrientation });
  // const mainElem = node.querySelector(".measure-display-view");
  // const creditElem = mainElem.querySelector(".credit");

  // cssParameters.map((argName) => {
  //   if (cssArgs && cssArgs[argName]) {
  //     const value = cssArgs[argName];
  //     if (value != null) {
  //       mainElem.style.setProperty(`--${camelToSnakeCase(argName)}`, value);
  //     }
  //   }
  // });

  function updateView() {
    // console.log("update view", mainElem);
    mainElem.style.setProperty("--mm-px-ratio", ratio.toString());
  }

  function getRatio() {
    return ratio;
  }

  function setRatio(value: number) {
    ratio = value;
    updateView();
    result.onChange(ratio);
  }

  function getDisplayDimensions() {
    // We could re-use ratio, but just in case it was edited from the outside,
    // we recompute it from what's displayed.
    const creditMMWidth = 85.6;
    // +getComputedStyle(creditElem).getPropertyValue("--credit-width");
    // console.log(getComputedStyle(creditElem));
    const { width: screenPxWidth, height: screenPxHeight } = window.screen;
    const { width: creditPxWidth } = creditElem.getBoundingClientRect();

    const mmPxRatio = creditPxWidth / creditMMWidth;
    // console.log(
    //   "mmPxRatio",
    //   creditPxWidth / creditMMWidth / window.devicePixelRatio
    // );
    return {
      width: screenPxWidth / mmPxRatio,
      height: screenPxHeight / mmPxRatio,
    };
  }

  function remove() {
    // stopHDrag();
    // stopVDrag();
    mainElem.remove();
  }

  const result = {
    onChange,
    getRatio,
    setRatio,
    getDisplayDimensions,
    remove,
  };

  updateView();

  return result;
}
