import { useEffect, useRef } from "react";
import { CircuitBoardLogic } from "./circuit-board-logic";
import "./circuit-board.scss";

export const CircuitBoard = () => {
  const circuitBoardRef = useRef<HTMLCanvasElement>(null);
  const circuitInstanceRef = useRef<InstanceType<typeof CircuitBoardLogic>>();

  const requestId = useRef<number>(0);
  const timer = useRef<number>(Date.now());

  useEffect(() => {
    if (!circuitBoardRef.current) return;
    circuitInstanceRef.current = new CircuitBoardLogic(
      circuitBoardRef.current as HTMLCanvasElement
    );
    circuitInstanceRef.current.gameMove();

    circuitInstanceRef.current.pulse();

    requestId.current = requestAnimationFrame(animate);
    return () => {
      cancelAnimationFrame(requestId.current);
    };
  });

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const animate = (_timestamp: number) => {
    const interval = Date.now() - timer.current;

    if (circuitInstanceRef.current) {
      circuitInstanceRef.current.gameMove();
    }

    if (interval > 2000 && circuitInstanceRef.current) {
      timer.current = Date.now();
      circuitInstanceRef.current.pulse();
    }

    requestId.current = requestAnimationFrame(animate);
  };

  return <canvas className="c-circuit-board" ref={circuitBoardRef} />;
};
