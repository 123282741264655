import {
  StepperState,
  Steps,
  OnboarderState,
  ExtraStepProps,
} from "@the-immersive/types";
import { signal, useSignalEffect, effect } from "@preact/signals-react";

export const isPopoverOpenSignal = signal<boolean>(false);
export const isEyeballVisibleSignal = signal<boolean>(true);
export const currentHintsSignal = signal<string[]>([]);
export const machineExplodingState = signal<
  "not-exploded" | "exploding" | "exploded" | "repairing" | "repaired"
>("not-exploded");

export const successMessages = signal<string[]>([]);

export const isModalOpenSignal = signal<boolean>(false);

// export const previousAnswers = signal<string[]>([]);

export const globalStateSignal = signal<
  Steps<OnboarderState, ExtraStepProps> | undefined
>(undefined);

export const currentStepNameSignal = signal<keyof StepperState | undefined>(
  undefined
);

export const correctAnswersSignal = signal<string[]>([]);

export const hasCorrectAnswerMessageBeenSeen = signal<boolean>(false);

export const videoTransitionState = signal<"playing" | "not-started" | "done">(
  "done"
);

export const globalVolumeNumber = signal<number>(1);

// export const goToStepSignal = signal<(step: Puzzle_Steps_Enum) => void>(() => {
// console.log("go to step", step);
// });

// export const resetSignal = signal<() => void>(() => {
// console.log("reset signal");
// });

export { signal, useSignalEffect, effect };
