import Color from "color";

const getRandom = (min: number, max: number) => {
  return Math.random() * (max - min) + min;
};

class Particle {
  constructor({
    x,
    y,
    speed,
    color,
    canvasContext,
  }: {
    x: number;
    y: number;
    speed: {
      x: number;
      y: number;
    };
    color: string;
    canvasContext: CanvasRenderingContext2D;
  }) {
    this.x = x;
    this.y = y;
    this.timeRemaining = 1;
    this.agingRate = getRandom(0.8, 0.99);
    this.speed = speed;
    this.color = Color(color);

    // console.log("this.agingRate", this.agingRate);
    this.upd = () => {
      canvasContext.strokeStyle = this.color
        .fade(this.timeRemaining)
        .hsl()
        .toString();
      canvasContext.lineWidth = 1;
      canvasContext.lineCap = "round";
      canvasContext.beginPath();
      canvasContext.moveTo(this.x, this.y);

      this.timeRemaining *= this.agingRate;

      // console.log("timeRemaining", canvasContext.strokeStyle);

      this.x += this.speed.x;
      this.y += this.speed.y;
      canvasContext.lineTo(this.x, this.y);
      canvasContext.stroke();

      this.angle = Math.atan2(this.speed.y, this.speed.x);
      this.magnitude = Math.sqrt(this.speed.x ** 2 + this.speed.y ** 2);
      const op = [this.angle + Math.PI / 4, this.angle - Math.PI / 4];
      const ch = Math.floor(Math.random() * op.length);
      if (Math.random() < 0.05) {
        this.speed.x = Math.cos(op[ch]) * this.magnitude;
        this.speed.y = Math.sin(op[ch]) * this.magnitude;
      }
    };
  }

  angle?: number;
  magnitude?: number;

  x: number;
  y: number;
  speed: {
    x: number;
    y: number;
  };
  color: Color;
  timeRemaining: number;
  agingRate: number;
  // color: string;

  upd() {}
}

export class CircuitBoardLogic {
  speed = 5;
  period = 1000;

  canvas: HTMLCanvasElement;
  context: CanvasRenderingContext2D;
  particles: Particle[] = [];

  constructor(canvas: HTMLCanvasElement) {
    if (!canvas) throw Error("missing canvas!");
    this.canvas = canvas;
    this.canvas.style.background = "rgba(0, 0, 0, .1)";
    this.canvas.width = document.body.clientWidth;
    this.canvas.height = document.body.clientHeight;

    this.context = canvas.getContext("2d", {
      willReadFrequently: true,
    }) as CanvasRenderingContext2D;

    window.addEventListener("resize", this.resizeCanvas.bind(this));
  }

  resizeCanvas() {
    const target = document.body;

    this.canvas.height = target.clientHeight;
    this.canvas.width = target.clientWidth;
  }

  clear() {
    this.context.save();
    this.context.globalCompositeOperation = "xor";
    this.context.fillStyle = "rgba(0,0,0,.05)";

    this.context.fillRect(0, 0, this.canvas.width, this.canvas.height);

    this.context.restore();

    // Restore the transform
    // this.context.restore();
  }

  superClear() {
    // this.context.save();
    // Use the identity matrix while clearing the canvas
    // this.context.fillStyle = "rgba(0,0,0,.9)";
    // this.context.fillRect(0, 0, this.canvas.width, this.canvas.height);
    // this.context.clearRect(0, 0, this.canvas.width, this.canvas.height);
    // this.context.setTransform(1, 0, 0, 1, 0, 0);
    // this.context.restore();
    // this.context.fillRect(0, 0, this.canvas.width, this.canvas.height);
    // Restore the transform
    // this.context.restore();
  }

  pulse() {
    const h = Math.random() * (210 - 150) + 150;

    // this.clear

    for (let i = 0; i < 56; i++) {
      this.particles.push(
        new Particle({
          x: this.canvas.width / 2,
          y: this.canvas.height / 2.6,
          speed: {
            x: Math.cos((i / 8) * 2 * Math.PI) * this.speed,
            y: Math.sin((i / 8) * 2 * Math.PI) * this.speed,
          },
          color: "hsla(" + h + ",100%,50%)",
          canvasContext: this.context,
        })
      );
    }
  }

  gameMove() {
    // requestAnimationFrame(this.gameMove);
    this.clear();

    // if (this.particles.length < 1) {
    // this.pulse();
    // }

    // console.log("remaining", this.particles.length);

    for (let i = 0; i < this.particles.length; i++) {
      this.particles[i].upd();
      if (
        this.particles[i].x < 0 ||
        this.particles[i].x > this.canvas.width ||
        this.particles[i].y < 0 ||
        this.particles[i].y > this.canvas.height
        // this.particles[i].timeRemaining <= 0.0001
      ) {
        // this.particles[i].color;
        this.particles.splice(i, 1);
      }
    }
  }
}
